import { SmallLoader } from './SmallLoader'

interface TextLoaderProps {
	text: string
	position: 'top' | 'bottom'
}

const TextLoader = (props: TextLoaderProps) => {
	const { text, position } = props

	return (
		<div className='small-loader-with-text'>
			<p className='primary'>{position === 'top' && text}</p>
			<SmallLoader />
			<p className='primary'>{position === 'bottom' && text}</p>
		</div>
	)
}

export { TextLoader }
