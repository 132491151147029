export interface FontAwesomeIconProps {
	style?: 'solid' | 'regular' | 'ligth' | 'thin' | 'duotone' | 'brands'
	iconCode: string
}

const FontAwesomeIcon = (props: FontAwesomeIconProps) => {
	return <i className={`fa-${props?.style || 'regular'} fa-${props.iconCode}`} />
}

export { FontAwesomeIcon }
