import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { DefaultLabel } from '../../../../components/inputs/DefaultLabel'
import { AcronymModel } from '../../../../models/acronym/AcronymModel'
import { AcronymService } from '../../../../services/acronymService/acronymService'
import { useNotaFiscal } from '../../../components/providers/notaFiscalProvider/NotaFiscalProvider'
import { Button as MUIButton } from '@mui/material'

const ReviewInput = (props: { clickHandler: (acronym: string | undefined) => void }) => {
	const { nota } = useNotaFiscal()
	const { clickHandler } = props
	const [acronym, setAcronym] = useState<AcronymModel>({
		text: nota ? (nota.infCpl ? nota.infCpl : '') : '',
	})
	const [acronymSelect, setAcronymSelect] = useState<string | undefined>('')
	const input = useRef<HTMLTextAreaElement>(null)
	const confirm = useRef<HTMLButtonElement>(null)
	const { isLoading, data: acronyms } = useQuery('acronym', () => AcronymService.list(), {
		retry: false,
	})

	useEffect(() => {
		input?.current?.focus()

		document.addEventListener(
			'keydown',
			(event) => {
				if (event.code === 'Enter' || event.code === 'NumpadEnter') confirm?.current?.click()
			},
			false
		)
	}, [props])

	useEffect(() => {
		if (acronyms && acronyms.length > 0) {
			const [defaultInfo] = acronyms?.filter((acronym) => acronym.isDefault)
			if (defaultInfo) {
				setAcronym(defaultInfo)
				setAcronymSelect(defaultInfo.text)
			}
		}
	}, [acronyms])

	const changeInfo = (e: React.FormEvent<HTMLTextAreaElement>) => {
		const newInfo = e.currentTarget.value
		setAcronym({ ...acronym, text: newInfo })
	}

	const setAcronymSelectText = (event: SelectChangeEvent) => {
		setAcronymSelect(event.target.value)
		setAcronym({ ...acronym, text: event.target.value })
	}

	const confirmClick = () => {
		clickHandler(acronym.text)
	}

	return (
		<div className='input-container'>
			<DefaultLabel title='Selecionar informação salva'>
				<Select
					value={acronymSelect}
					onChange={setAcronymSelectText}
					autoWidth
					disabled={acronymSelect == undefined}
					sx={{
						width: '32vw',
						color: '#000',
						backgroundColor: '#ffffffb3',
						'&.MuiOutlinedInput-root': {
							'&.Mui-focused fieldset': {
								borderColor: '#ffffffb3',
							},
							'&:hover fieldset': {
								borderColor: '#ffffffb3',
							},
						},
					}}
					MenuProps={{
						PaperProps: {
							sx: {
								color: '#000',
								maxWidth: '32vw',
								// '&& .Mui-selected': {
								// 	backgroundColor: '#cecece',
								// 	'&:hover': {
								// 		backgroundColor: '#6cae17',
								// 	},
								// },
							},
						},
						MenuListProps: {
							sx: {
								'& .MuiMenuItem-root': {
									whiteSpace: 'normal',
									overflow: 'auto',
								},
							},
						},
					}}
				>
					{acronyms?.map((acronym) => (
						<MenuItem value={acronym.text}>{acronym.text}</MenuItem>
					))}
				</Select>
			</DefaultLabel>
			<label className='default-label'>
				Informação complementar
				<textarea
					className='default-input'
					ref={input}
					autoFocus
					value={acronym.text}
					placeholder='Digite aqui...'
					onChange={changeInfo}
				/>
			</label>
			<MUIButton
				onClick={confirmClick}
				ref={confirm}
				variant='contained'
				color='primary'
				size='medium'
			>
				SALVAR
			</MUIButton>
		</div>
	)
}

export { ReviewInput }
