import { TransactionModel } from '../models/TransactionModel'
import { CfopCard } from './CfopCard'

const CfopList = (props: {
	cfops: TransactionModel[]
	clickHandler: (cfop: TransactionModel) => void
}) => {
	const { cfops, clickHandler } = props
	return (
		<div className='cfop-list'>
			{cfops
				.sort((cfop1, cfop2) => parseInt(cfop1.code) - parseInt(cfop2.code))
				.map((cfop: TransactionModel) => (
					<CfopCard {...{ cfop, clickHandler }} />
				))}
		</div>
	)
}

export { CfopList }
