import { Masks } from '../utils/masks/masks'
import axios from 'axios'

const apiCep = axios.create({
	baseURL: 'https://viacep.com.br/ws/',
	timeout: 10000,
})

export const fetchCep = async (cep: string) => {
	const postalCode = Masks.clean(cep)
	if (postalCode.length === 8) {
		const response = await apiCep.get(postalCode + '/json/')
		const { bairro, localidade, logradouro, uf } = response.data
		return {
			state: uf,
			district: bairro,
			city: localidade,
			street: logradouro,
		}
	}
}
