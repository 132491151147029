import {
	CorrectionModel,
	CreateCorrectionModel,
} from '../../app/modules/correction/models/CorrectionModel'
import { baseApi } from '../api/base'
import { keysToSnake } from '../utils/caseConverter'

const correctionApi = baseApi('/corrections')
const correctionApiWithoutErrors = baseApi('/corrections', '', false)

const get = async (saleId: number): Promise<CorrectionModel> => {
	return (await correctionApi.get(saleId.toString())).data
}

const getWithoutErrors = async (saleId: number): Promise<CorrectionModel> => {
	return (await correctionApiWithoutErrors.get(saleId.toString())).data
}

const getXml = async (saleId: number): Promise<string> => {
	return (await correctionApi.get(`${saleId.toString()}/xml`)).data.url
}

const getPdf = async (saleId: number): Promise<string> => {
	return (await correctionApi.get(`${saleId.toString()}/pdf?content_disposition=inline`)).data.url
}

const create = async (correction: CreateCorrectionModel): Promise<CorrectionModel> => {
	return (await correctionApi.post('', keysToSnake(correction))).data
}

export const CorrectionService = {
	get,
	getWithoutErrors,
	create,
	getXml,
	getPdf,
}
