import FlatList from 'flatlist-react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { IconButton } from '../../../../components/buttons/IconButton'
import { FontAwesomeIcon } from '../../../../components/icon/FontAwesomeIcon'
import {
	NotaFiscalFilterParams,
	NotaFiscalService,
} from '../../../../services/notaFiscal/notaFiscalService'
import { NotaFiscalListModel, NotaFiscalModel } from '../models/NotaFiscalModels'
import { NFEButton } from './NFEButton'
import { moneyFormatter } from '../../../../utils/moneyFormatter'
import { SmallLoader } from '../../../../components/smallLoader/SmallLoader'
import { useBasicModal } from '../../../components/providers/basicModalProvider/BasicModalProvider'
import { NfeFilterModal } from './NfeFilterModal'

interface NFEButtonListProps {
	clickHandler: (id: number) => void
	selectedNFE?: NotaFiscalModel
	onFilter?: () => void
	updateList?: boolean
}

const NFEButtonList = (props: NFEButtonListProps) => {
	const { updateState } = useBasicModal()
	const [nfes, setNfes] = useState<NotaFiscalListModel[]>([])
	const [page, setPage] = useState<number>(1)
	const [daysLastNfe, setDaysLastNfe] = useState<string | undefined>()
	const navigate = useNavigate()
	const { clickHandler, selectedNFE, onFilter, updateList } = props
	const [filters, setFilters] = useState<NotaFiscalFilterParams>({ invoice: {} })
	const [hasMoreItems, setHasMoreItems] = useState<boolean>(true)

	useEffect(() => {
		if (updateList) {
			resetList()
			listNfe(1)
		}
	}, [updateList])

	const resetList = async () => {
		setPage(1)
		setHasMoreItems(true)
		setNfes([])
	}

	useEffect(() => {
		resetList()
		if (hasMoreItems) {
			onFilter?.()
			listNfe()
		}
	}, [filters])

	const listNfe = async (forcePage?: number) => {
		await NotaFiscalService.list({
			page: forcePage || page,
			per_page: 10,
			sort_field: 'id',
			sort_order: 'desc',
			...filters,
		}).then((res: NotaFiscalListModel[]) => {
			if (res.length < 10) {
				setHasMoreItems(false)
			}
			setNfes((prev: NotaFiscalListModel[]) => [...prev, ...res])
		})
		setPage((prev: number) => prev + 1)
	}

	const filterNfe = (params: NotaFiscalFilterParams) => {
		resetList()
		setFilters(params)
	}

	const setBasicModal = () => {
		updateState?.({ isOpen: true, basicModalContent: <NfeFilterModal filterHandler={filterNfe} /> })
	}

	const isNfeSelected = (nfeId: number) => {
		return nfeId === selectedNFE?.id
	}

	const renderNfe = (nfe: NotaFiscalListModel) => {
		return <NFEButton {...{ nfe, clickHandler, isNfeSelected }} />
	}

	const getNfesTotalValue = () => {
		const res = nfes.reduce(
			(previousValue, currentValue) =>
				previousValue + currentValue.totalAmount ? parseFloat(currentValue.totalAmount) : 0,
			0
		)
		return res
	}

	const getDaysUntilNowLastNfe = () => {
		const lastNfe = nfes[nfes.length - 1]
		if (!lastNfe?.openDate) return
		const lastNfeDate = new Date(lastNfe.openDate)
		const dateDiff = Date.now() - lastNfeDate.getTime()
		const days = Math.floor(dateDiff / (1000 * 60 * 60 * 24))
		if (days <= 1) return 'Último dia'
		return `Últimos ${days} dias`
	}

	useEffect(() => {
		const days = getDaysUntilNowLastNfe()
		setDaysLastNfe(days)
	}, [nfes])

	const goToPreviousPage = () => {
		navigate('/homepage')
	}

	return (
		<div className='nfeButtonsList'>
			<div className='header'>
				<IconButton iconCode='arrow-left' style='solid' clickHandler={goToPreviousPage} />
				<p className='primary'>Notas emitidas</p>
				<IconButton iconCode='ellipsis-vertical' style='solid' clickHandler={setBasicModal} />
			</div>
			<div className='subHeader'>
				<p className='primary'>{daysLastNfe}</p>
				<p className='secondary'>
					{nfes.length} Notas - {moneyFormatter.format(getNfesTotalValue())}
				</p>
			</div>
			<div className='buttonsList'>
				{nfes && (
					<FlatList
						list={nfes}
						renderItem={renderNfe}
						hasMoreItems={hasMoreItems}
						loadMoreItems={listNfe}
						renderWhenEmpty={() => <SmallLoader />}
						paginationLoadingIndicator={<SmallLoader />}
						paginationLoadingIndicatorPosition={'center'}
					/>
				)}
			</div>
		</div>
	)
}

export { NFEButtonList }
