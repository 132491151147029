import { ReactNode } from 'react'
import { CardButton, CardButtonProps } from '../../../../components/buttons/CardButton'
import { PaymentMethodModel } from '../models/PaymentMethodModel'

interface PaymentMethodButtonProps {
	id?: string
	payment: PaymentMethodModel
	icon: ReactNode
	clickHandler?: (method: PaymentMethodModel) => void
	isSelected?: boolean
}

const PaymentMethodButton = (props: PaymentMethodButtonProps) => {
	const { id, payment, icon, clickHandler, isSelected } = props
	const { name } = payment

	const click = () => {
		clickHandler?.(payment)
	}

	const card: CardButtonProps = {
		id,
		name,
		icon,
		clickHandler: click,
		styleClasses: 'large',
		isSelected,
	}

	return <CardButton {...card} />
}

export { PaymentMethodButton }
