import { PaymentModel } from '../models/PaymentModel'
import { SelectedPaymentCard } from './SelectedMethodCard'

const PaymentCest = (props: { payments: PaymentModel[]; removeHandler: (id: number) => void }) => {
	const { payments, removeHandler } = props
	return (
		<div className='cest-container'>
			{payments.map((p: PaymentModel) => (
				<SelectedPaymentCard {...{ payment: p, removeHandler }} />
			))}
		</div>
	)
}

export { PaymentCest }
