import { useRegistrationModal } from '../../app/components/providers/registrationModalProvider/RegistrationModalProvider'

const RegistrationModal = () => {
	const { modalContent } = useRegistrationModal()
	return (
		<div className='modal fade' id='modal_resgistration' aria-hidden='true'>
			<div className='modal-dialog'>
				<div className='modal-content registration-modal'>{modalContent}</div>
			</div>
		</div>
	)
}

export { RegistrationModal }
