export interface PageHeaderProps {
	title: string
}

const PageHeader = (props: PageHeaderProps) => {
	const { title } = props

	return (
		<div className='pageHeader'>
			<h1 className='page-title'>{title}</h1>
		</div>
	)
}

export { PageHeader }
