import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { FC } from 'react'
import { useLocation } from 'react-router-dom'
import { useLayout } from '../../../../_metronic/layout/core'

interface AsideDisplayControl {
	left: boolean
	right: boolean
}

interface setAsideContentFunction {
	(content: ReactNode): void
}

type AsideProviderProps = {
	leftAsideContent: ReactNode | null
	rightAsideContent: ReactNode | null
	setLeftAsideContent: setAsideContentFunction | null
	setRightAsideContent: setAsideContentFunction | null
}

const AsideContext = createContext<AsideProviderProps>({
	leftAsideContent: null,
	rightAsideContent: null,
	setLeftAsideContent: null,
	setRightAsideContent: null,
})

const AsideProvider: FC = ({ children }) => {
	const location = useLocation()

	const { config, setLayout } = useLayout()
	const [leftAsideContent, setLeftAsideContent] = useState<ReactNode>()
	const [rightAsideContent, setRightAsideContent] = useState<ReactNode>()

	const getDefaultAsidesDisplayPerRoute = () => {
		const asidesDisplayPerRoute = new Map<string, AsideDisplayControl>()
		asidesDisplayPerRoute.set('/transacao', { left: true, right: false })
		asidesDisplayPerRoute.set('/adicionarItems', { left: true, right: true })
		asidesDisplayPerRoute.set('/formaDePagamento', { left: true, right: true })
		asidesDisplayPerRoute.set('/selecionarProdutor', { left: true, right: false })
		asidesDisplayPerRoute.set('/selecionarDestinatario', { left: true, right: false })
		asidesDisplayPerRoute.set('/selecionarTransportadora', { left: true, right: false })
		asidesDisplayPerRoute.set('/detalhesDaNota', { left: true, right: false })
		asidesDisplayPerRoute.set('/revisao', { left: true, right: false })
		asidesDisplayPerRoute.set('/concluido', { left: true, right: true })
		asidesDisplayPerRoute.set('/planos', { left: false, right: false })
		return asidesDisplayPerRoute
	}

	const hideAsides = () => {
		updateLayoutBothAsides(false, false)
	}

	const updateLayoutBothAsides = (showLeft: boolean, showRight: boolean) => {
		setLayout({
			aside: {
				...config.aside,
				display: showLeft,
			},
			rightAside: {
				...config.rightAside,
				display: showRight,
			},
		})
	}

	// const updateLayout = (name: 'aside' | 'rightAside', show: boolean) => {
	// 	setLayout({
	// 		[name]: {
	// 			...config[name],
	// 			display: show,
	// 		},
	// 	})
	// }

	const getDisplayConfigForCurrentRoute = () => {
		return getDefaultAsidesDisplayPerRoute().get(location.pathname)
	}

	const initCurrentRouteAsideDisplayConfig = () => {
		const currentDisplay = getDisplayConfigForCurrentRoute()

		if (!currentDisplay) {
			hideAsides()
			return
		}

		updateLayoutBothAsides(currentDisplay.left, currentDisplay.right)
	}

	useEffect(() => {
		initCurrentRouteAsideDisplayConfig()
	}, [location])

	const value = {
		setLeftAsideContent,
		setRightAsideContent,
		leftAsideContent,
		rightAsideContent,
	}

	return <AsideContext.Provider value={value}>{children}</AsideContext.Provider>
}

export { AsideProvider }

export function useAside() {
	return useContext(AsideContext)
}
