import { FontAwesomeIcon } from '../icon/FontAwesomeIcon'

const TrashButton = (props: { classes?: string; clickHandler?: () => void }) => {
	const { classes, clickHandler } = props
	return (
		<button onClick={clickHandler} className={`default-button danger trashBin ${classes}`}>
			<FontAwesomeIcon iconCode='trash-can' />
		</button>
	)
}

export { TrashButton }
