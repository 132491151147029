import { createContext, FC, ReactNode, useContext, useState } from 'react'

interface setModalContentFunction {
	(content: ReactNode): void
}

interface RegistrationModalProps {
	modalContent: ReactNode | null
	setModalContent: setModalContentFunction | null
}

const RegistrationModalContext = createContext<RegistrationModalProps>({
	modalContent: null,
	setModalContent: null,
})

const RegistrationModalProvider: FC = ({ children }) => {
	const [modalContent, setModalContent] = useState<ReactNode>()

	const value = {
		modalContent,
		setModalContent,
	}

	return (
		<RegistrationModalContext.Provider value={value}>{children}</RegistrationModalContext.Provider>
	)
}

export { RegistrationModalProvider }

export function useRegistrationModal() {
	return useContext(RegistrationModalContext)
}
