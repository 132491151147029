import { IssueViewProps } from '../../app/modules/notaFiscal/components/IssueView'

export interface NavigatorButtonProps {
	view: IssueViewProps
	selected?: boolean
	clickHandler?: (view: IssueViewProps) => void
}

const NavigatorButton = (props: NavigatorButtonProps) => {
	const { view, selected, clickHandler } = props

	const click = () => {
		clickHandler?.(view)
	}

	return (
		<button className={`navigator-button ${selected ? 'selected' : ''}`} onClick={click}>
			<p className='primary'>{view?.title}</p>
		</button>
	)
}

export { NavigatorButton }
