import moment from 'moment-timezone'

const Date = (props: { time: string; fromNow?: boolean }) => {
	const { time, fromNow } = props
	moment.relativeTimeThreshold('m', 59)
	moment.locale('pt-br', {
		relativeTime: {
			future: 'Em %s',
			past: '%s',
			ss: 'Há %s segundos',
			m: 'Há 1 minuto',
			mm: 'Há %d minutos',
			h: 'Há 1 hora',
			hh: 'Há %d horas',
			d: 'Ontem',
			dd: 'Há %d dias',
		},
	})

	const format = (time: string) => {
		return moment.utc(time).tz('America/Sao_Paulo').format('DD/MM/YYYY, HH:mm')
	}

	const customFromNow = (time: string) => {
		const timeMoment = moment.utc(time).tz('America/Sao_Paulo')
		const diff = Math.abs(moment().diff(timeMoment))
		if (diff < 1000 * 172800) {
			// < 2 dias
			if (diff < 1000 * 60) {
				// < 1 min
				return 'Agora'
			}
			return moment.utc(time).fromNow()
		}
		return format(time)
	}

	return <p className='primary'>{fromNow ? customFromNow(time) : format(time)}</p>
}

export { Date }
