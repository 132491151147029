import { useRef, useState } from 'react'
import { useFormik } from 'formik'
import { CreatePersonModel } from '../../../../models/person/CreatePersonModel'
import { YupSchemas } from '../../../../services/utils/yupSchemas/YupSchemas'
import { ClientService } from '../../../../services/client/clientService'
import { Masks } from '../../../../services/utils/masks/masks'
import { fetchCep } from '../../../../services/api/cepApi'
import { FontAwesomeIcon } from '../../../../components/icon/FontAwesomeIcon'
import { useLoader } from '../../../components/providers/loaderProvider/LoaderProvider'

const inits: CreatePersonModel = {
	name: '',
	fancyName: '',
	role: 0,
	document: '',
	stateRegistry: '',
	stateRegistryIsento: false,
	email: '',
	phoneNumber: '',
	mobileNumber: '',
	postalCode: '',
	country: 'Brasil',
	state: '',
	city: '',
	district: '',
	street: '',
	streetNumber: 0,
	noStreetNumber: false,
	complement: '',
}

const ClientModal = (props: { updateList?: () => void }) => {
	const { updateList } = props
	const setLoading = useLoader()
	const [schema] = useState(YupSchemas.personSchema)
	const [initValues] = useState<CreatePersonModel>(inits)
	const closeModal = useRef<HTMLButtonElement>(null)
	const [cepFetched, setCepFetched] = useState<boolean>(false)

	const formik = useFormik({
		initialValues: initValues,
		validationSchema: schema,
		onSubmit: (createdPerson) => cleanMasks(createdPerson),
	})

	const create = async (payload: CreatePersonModel) => ClientService.create(payload)

	const cleanMasks = async (createdPerson: CreatePersonModel) => {
		const cleanMasks: CreatePersonModel = {
			...createdPerson,
			phoneNumber: Masks.clean(createdPerson.phoneNumber),
			mobileNumber: Masks.clean(createdPerson.mobileNumber),
			document: Masks.clean(createdPerson.document),
			stateRegistry: Masks.clean(createdPerson.stateRegistry),
			postalCode: Masks.clean(createdPerson.postalCode),
			city: Masks.noSpecialCharacters(createdPerson.city),
			country: Masks.noSpecialCharacters(createdPerson.country),
			district: Masks.noSpecialCharacters(createdPerson.district),
			street: Masks.noSpecialCharacters(createdPerson.street),
			complement: Masks.noSpecialCharacters(createdPerson.complement),
			state: Masks.noSpecialCharacters(createdPerson.state),
		}
		delete cleanMasks.noStreetNumber
		setLoading?.(true)
		await create(cleanMasks).then(() => {
			updateList?.()
			formik.resetForm()
			closeModal?.current?.click()
		})
		setLoading?.(false)
	}

	const onChangePostalCode = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const postalCode = e.target.value
		formik.setFieldValue('postalCode', postalCode, true)
		const endereco = await fetchCep(postalCode)
		if (endereco) {
			setCepFetched(true)
			const { state, district, city, street } = endereco
			formik.setFieldValue('state', state, true)
			formik.setFieldValue('district', district, true)
			formik.setFieldValue('city', city, true)
			formik.setFieldValue('street', street, true)
			return
		}
		setCepFetched(false)
	}

	return (
		<>
			<div className='modal-header'>
				<p className='title'>Adicionar destinatário</p>
				<button ref={closeModal} type='button' className='iconButton' data-bs-dismiss='modal'>
					<FontAwesomeIcon iconCode='x' />
				</button>
			</div>
			<div className='modal-body'>
				<form onSubmit={formik.handleSubmit}>
					<div className='form-container'>
						<label htmlFor='name' className='default-label form-content-label'>
							<p className='primary'>Dados gerais</p>
						</label>
						<div className='modal-input-container'>
							<label className='default-label'>Tipo</label>
							<select
								className='form-select'
								aria-label='Select example'
								name='role'
								value={formik.values.role}
								onChange={formik.handleChange}
								itemType='number'
							>
								<option value={0}>CPF</option>
								<option value={1}>CNPJ</option>
							</select>
						</div>
						<div className='modal-input-container'>
							<label htmlFor='name' className='required default-label'>
								Nome
							</label>
							<input
								className={`form-control ${
									formik.errors.name && formik.touched.name && 'is-invalid'
								}`}
								id='name'
								name='name'
								type='text'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.name}
							/>
							{formik.touched.name && formik.errors.name ? (
								<div className='invalid-feedback'>{formik.errors.name}</div>
							) : null}
						</div>

						{formik.values.role == 1 && (
							<div className='modal-input-container'>
								<label htmlFor='fancyName' className='default-label'>
									Nome fantasia
								</label>
								<input
									className={`form-control ${
										formik.errors.fancyName && formik.touched.fancyName && 'is-invalid'
									}`}
									id='fancyName'
									name='fancyName'
									type='text'
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.fancyName}
								/>
								{formik.touched.fancyName && formik.errors.fancyName ? (
									<div className='invalid-feedback'>{formik.errors.fancyName}</div>
								) : null}
							</div>
						)}

						<div className='modal-input-container'>
							<label htmlFor='document' className='required default-label'>
								{formik.values.role == 1 ? 'CNPJ' : 'CPF'}
							</label>
							<input
								className={`form-control ${
									formik.errors.document && formik.touched.document && 'is-invalid'
								}`}
								id='document'
								name='document'
								type='text'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={
									formik.values.role == 1
										? Masks.formatCnpj(formik.values.document)
										: Masks.formatCpf(formik.values.document)
								}
							/>
							{formik.touched.document && formik.errors.document ? (
								<div className='invalid-feedback'>{formik.errors.document}</div>
							) : null}
						</div>
						<div className='modal-input-container'>
							<label htmlFor='email' className='required default-label'>
								Email
							</label>
							<input
								className={`form-control ${
									formik.errors.email && formik.touched.email && 'is-invalid'
								}`}
								id='email'
								name='email'
								type='email'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.email}
							/>
							{formik.touched.email && formik.errors.email ? (
								<div className='invalid-feedback'>{formik.errors.email}</div>
							) : null}
						</div>

						<div className='modal-input-container'>
							<label htmlFor='phoneNumber' className='default-label'>
								Telefone
							</label>
							<input
								className={`form-control ${
									formik.errors.phoneNumber && formik.touched.phoneNumber && 'is-invalid'
								}`}
								id='phoneNumber'
								name='phoneNumber'
								type='text'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={Masks.formatPhone(formik.values.phoneNumber)}
							/>
							{formik.touched.phoneNumber && formik.errors.phoneNumber ? (
								<div className='invalid-feedback'>{formik.errors.phoneNumber}</div>
							) : null}
						</div>

						<div className='modal-input-container'>
							<label htmlFor='mobileNumber' className='default-label'>
								Celular
							</label>
							<input
								className={`form-control ${
									formik.errors.mobileNumber && formik.touched.mobileNumber && 'is-invalid'
								}`}
								id='mobileNumber'
								name='mobileNumber'
								type='text'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={Masks.formatPhone(formik.values.mobileNumber)}
							/>
							{formik.touched.mobileNumber && formik.errors.mobileNumber ? (
								<div className='invalid-feedback'>{formik.errors.mobileNumber}</div>
							) : null}
						</div>
						<div className='modal-input-container ie'>
							<label htmlFor='stateRegistry' className='default-label'>
								Inscrição Estadual
							</label>
							<div className='input-group'>
								<span className='input-group-text' id='basic-addon1'>
									<div className='form-check form-switch form-check-custom form-check-solid'>
										<input
											className='form-check-input dark-switch'
											type='checkbox'
											name='stateRegistryIsento'
											id='flexSwitchChecked'
											onChange={formik.handleChange}
											checked={formik.values.stateRegistryIsento}
										/>
										<label className='form-check-label' htmlFor='flexSwitchChecked'>
											Sou isento
										</label>
									</div>
								</span>
								<input
									className={`form-control ${
										formik.errors.stateRegistry &&
										formik.touched.stateRegistry &&
										!formik.values.stateRegistryIsento &&
										'is-invalid'
									}`}
									id='stateRegistry'
									name='stateRegistry'
									type='text'
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={
										formik.values.stateRegistryIsento
											? 'ISENTO'
											: Masks.formatIE(formik.values.stateRegistry)
									}
								/>
								{formik.touched.stateRegistry && formik.errors.stateRegistry ? (
									<div className='invalid-feedback'>{formik.errors.stateRegistry}</div>
								) : null}
							</div>
						</div>
						<label htmlFor='postalCode' className='default-label form-content-label'>
							<p className='primary'>Endereço</p>
						</label>
						<div className='modal-input-container'>
							<label htmlFor='postalCode' className='required default-label'>
								CEP
							</label>
							<input
								className={`form-control ${
									formik.errors.postalCode && formik.touched.postalCode && 'is-invalid'
								}`}
								id='postalCode'
								name='postalCode'
								type='text'
								onChange={onChangePostalCode}
								onBlur={formik.handleBlur}
								value={Masks.formatCEP(formik.values.postalCode)}
							/>
							{formik.touched.postalCode && formik.errors.postalCode ? (
								<div className='invalid-feedback'>{formik.errors.postalCode}</div>
							) : null}
						</div>

						<div className='modal-input-container'>
							<label htmlFor='country' className='required default-label'>
								País
							</label>
							<input
								className={`form-control ${
									formik.errors.country && formik.touched.country && 'is-invalid'
								}`}
								id='country'
								name='country'
								type='text'
								disabled={cepFetched}
								readOnly
								onBlur={formik.handleBlur}
								value={Masks.noSpecialCharacters(formik.values.country)}
							/>
							{formik.touched.country && formik.errors.country ? (
								<div className='invalid-feedback'>{formik.errors.country}</div>
							) : null}
						</div>

						<div className='modal-input-container'>
							<label htmlFor='state' className='required default-label'>
								Estado
							</label>
							<input
								className={`form-control ${
									formik.errors.state && formik.touched.state && 'is-invalid'
								}`}
								id='state'
								name='state'
								type='text'
								disabled={cepFetched}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={Masks.noSpecialCharacters(formik.values.state)}
							/>
							{formik.touched.state && formik.errors.state ? (
								<div className='invalid-feedback'>{formik.errors.state}</div>
							) : null}
						</div>

						<div className='modal-input-container'>
							<label htmlFor='city' className='required default-label'>
								Cidade
							</label>
							<input
								className={`form-control ${
									formik.errors.city && formik.touched.city && 'is-invalid'
								}`}
								id='city'
								name='city'
								type='text'
								disabled={cepFetched}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={Masks.noSpecialCharacters(formik.values.city)}
							/>
							{formik.touched.city && formik.errors.city ? (
								<div className='invalid-feedback'>{formik.errors.city}</div>
							) : null}
						</div>

						<div className='modal-input-container'>
							<label htmlFor='district' className='required default-label'>
								Bairro
							</label>
							<input
								className={`form-control ${
									formik.errors.district && formik.touched.district && 'is-invalid'
								}`}
								id='district'
								name='district'
								type='text'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={Masks.noSpecialCharacters(formik.values.district)}
							/>
							{formik.touched.district && formik.errors.district ? (
								<div className='invalid-feedback'>{formik.errors.district}</div>
							) : null}
						</div>

						<div className='modal-input-container'>
							<label htmlFor='street' className='required default-label'>
								Rua
							</label>
							<input
								className={`form-control ${
									formik.errors.street && formik.touched.street && 'is-invalid'
								}`}
								id='street'
								name='street'
								type='text'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={Masks.noSpecialCharacters(formik.values.street)}
							/>
							{formik.touched.street && formik.errors.street ? (
								<div className='invalid-feedback'>{formik.errors.street}</div>
							) : null}
						</div>

						<div className='modal-input-container streetNumber'>
							<label htmlFor='streetNumber' className='required default-label'>
								Número
							</label>
							<div className='input-group'>
								<span className='input-group-text' id='basic-addon1'>
									<div className='form-check form-switch form-check-custom form-check-solid'>
										<input
											className='form-check-input dark-switch'
											type='checkbox'
											name='noStreetNumber'
											id='flexSwitchChecked2'
											onChange={formik.handleChange}
											checked={formik.values.noStreetNumber}
										/>
										<label className='form-check-label' htmlFor='flexSwitchChecked2'>
											Sem Número
										</label>
									</div>
								</span>
								<input
									className={`form-control ${
										formik.errors.streetNumber &&
										formik.touched.streetNumber &&
										!formik.values.noStreetNumber &&
										'is-invalid'
									}`}
									id='streetNumber'
									name='streetNumber'
									type={formik.values.noStreetNumber ? 'text' : 'number'}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.noStreetNumber ? 'Sem número' : formik.values.streetNumber}
								/>
								{formik.touched.streetNumber && formik.errors.streetNumber ? (
									<div className='invalid-feedback'>{formik.errors.streetNumber}</div>
								) : null}
							</div>
						</div>

						<div className='modal-input-container'>
							<label htmlFor='complement' className='default-label'>
								Complemento
							</label>
							<input
								className={`form-control ${
									formik.errors.complement && formik.touched.complement && 'is-invalid'
								}`}
								id='complement'
								name='complement'
								type='text'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={Masks.noSpecialCharacters(formik.values.complement)}
							/>
							{formik.touched.complement && formik.errors.complement ? (
								<div className='invalid-feedback'>{formik.errors.complement}</div>
							) : null}
						</div>
						<div className='form-submit'>
							<button type='button' className='default-button danger' data-bs-dismiss='modal'>
								Cancelar
							</button>
							<button className='default-button tertiary' type='submit'>
								Cadastrar
							</button>
						</div>
					</div>
				</form>
			</div>
		</>
	)
}
export { ClientModal }
