import clsx from 'clsx'
import { useAside } from '../../app/components/providers/asideProvider/AsideProvider'
import { useLayout } from '../../_metronic/layout/core'

const Aside = (props: { right?: boolean }) => {
	const { classes } = useLayout()
	const { leftAsideContent, rightAsideContent } = useAside()

	return (
		<div
			id={props.right ? 'kt_right-aside' : 'kt_aside'}
			className={
				props.right
					? clsx('right-aside', classes.rightAside.join(' '))
					: clsx('aside', classes.aside.join(' '))
			}
			data-kt-drawer='true'
			data-kt-drawer-name={props.right ? 'right-aside' : 'aside'}
			data-kt-drawer-activate='{default: true, lg: false}'
			data-kt-drawer-overlay='true'
			data-kt-drawer-width="{default:'200px', '300px': '250px'}"
			data-kt-drawer-direction='start'
			data-kt-drawer-toggle='#kt_aside_mobile_toggle'
		>
			{props.right ? rightAsideContent : leftAsideContent}
		</div>
	)
}

export { Aside }
