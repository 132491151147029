import { useEffect, useState } from 'react'
import { useAside } from '../../components/providers/asideProvider/AsideProvider'
import { NFEButtonList } from '../../modules/notaFiscal/components/NFEButtonList'
import {
	NotaFiscalIssueModel,
	NotaFiscalModel,
} from '../../modules/notaFiscal/models/NotaFiscalModels'
import { DefaultNotaFiscalContent } from '../../modules/notaFiscal/components/DefaultNotaFiscalContent'
import { NotaFiscalService } from '../../../services/notaFiscal/notaFiscalService'
import { IssueView, IssueViewProps } from '../../modules/notaFiscal/components/IssueView'
import { NFEDetail } from '../../modules/notaFiscal/components/NFEDetail'
import { NavigatorHeader } from '../../../components/pageHeader/NavigatorHeader'
import { Danfe } from '../../../components/danfe/Danfe'
import { useFooter } from '../../components/providers/footerProvider/FooterProvider'
import { notifyError, notifySuccess } from '../../../components/toasts/toasts'
import { NFEFooter } from '../../modules/notaFiscal/components/NFEFooter'
import {
	getStatusByNumber,
	NotaFiscalStatus,
} from '../../modules/notaFiscal/constants/NotaFiscalStatus'
import { useNotaFiscal } from '../../components/providers/notaFiscalProvider/NotaFiscalProvider'
import { useNavigate } from 'react-router-dom'
import { ProductModel } from '../../modules/product/models/ProductModel'
import { CCPdf } from '../../../components/cc/CCPdf'
import { useRegistrationModal } from '../../components/providers/registrationModalProvider/RegistrationModalProvider'
import { CorrectionModal } from '../../modules/correction/components/CorrectionModal'
import { DefaultCorrectionContent } from '../../modules/correction/components/DefaultCorrectionContent'

const NotaFiscalDetailPage = () => {
	const { updateNota } = useNotaFiscal()
	const { setModalContent } = useRegistrationModal()
	const { setLeftAsideContent } = useAside()
	const { setFooterContent } = useFooter()
	const [selectedNFE, setSelectedNFE] = useState<NotaFiscalModel | undefined>()
	const [view, setView] = useState<IssueViewProps>()
	const [isViewLoading, setIsViewLoading] = useState(false)
	const navigate = useNavigate()

	useEffect(() => {
		setLeftAsideContent?.(<NFEButtonList {...{ clickHandler: selectNFE, selectedNFE, onFilter }} />)
	}, [selectedNFE])

	useEffect(() => {
		if (!selectedNFE) {
			setFooterContent?.(null)
			setModalContent?.(null)
			return
		}
		setModalContent?.(<CorrectionModal saleId={selectedNFE.id} onEmission={onCorrectionEmission} />)
		setView(getDefaultView(selectedNFE))
	}, [selectedNFE])

	useEffect(() => {
		const loader = document.getElementById('nfe-view-loader')
		if (!isViewLoading) loader?.classList.add('loader-hidden')
		if (isViewLoading) loader?.classList.remove('loader-hidden')
	}, [isViewLoading])

	const onCorrectionEmission = () => {
		if (!selectedNFE) return
		const saleId = selectedNFE.id
		selectNFE(saleId)
	}

	const selectNFE = async (nfeId: number) => {
		setIsViewLoading?.(true)
		await NotaFiscalService.get(nfeId).then((nfe) => {
			setSelectedNFE(nfe)
			setIsViewLoading?.(false)
		})
	}

	const onFilter = () => {
		setSelectedNFE(undefined)
	}

	const updateList = () => {
		setLeftAsideContent?.(
			<NFEButtonList {...{ clickHandler: selectNFE, selectedNFE, onFilter, updateList: true }} />
		)
	}

	const cancelNfe = async (id: number) => {
		setIsViewLoading?.(true)
		await NotaFiscalService.cancel(id)
			.then(() => {
				updateList()
				notifySuccess('Nota cancelada com sucesso.')
			})
			.catch(() => {
				notifyError('Não foi possível cancelar a nota.')
			})
		setIsViewLoading?.(false)
	}

	const canBeCanceled = (nfe: NotaFiscalModel | undefined) => {
		if (!nfe) return false
		return getStatusByNumber(nfe.status) !== NotaFiscalStatus.CANCELADA
	}

	const canBeCloned = (nfe: NotaFiscalModel | undefined) => {
		if (!nfe) return false
		return getStatusByNumber(nfe.status) === NotaFiscalStatus.CONFIRMADA
	}

	const cancelClick = () => {
		if (selectedNFE) {
			cancelNfe(selectedNFE.id)
			setSelectedNFE(undefined)
		}
	}

	const cloneItems = (items: ProductModel[]) => {
		const test = items.map((i: ProductModel) => ({
			...i,
			id: i.productId || i.id,
		}))
		return test
	}

	const cloneClick = () => {
		if (selectedNFE) {
			const clonedNota: Partial<NotaFiscalIssueModel> = {
				...selectedNFE,
				issuerId: selectedNFE.issuer.id,
				items: cloneItems(selectedNFE.items),
			}
			updateNota?.(clonedNota)
			navigate('/revisao')
		}
	}

	const changeView = (view: IssueViewProps) => {
		setView(view)
	}

	const getViews = (nfe: NotaFiscalModel) => [
		{
			id: 1,
			title: 'Resumo',
			content: <NFEDetail nfe={nfe} />,
			footer: (
				<NFEFooter
					nfe={nfe}
					cloneClick={canBeCloned(selectedNFE) ? cloneClick : undefined}
					cancelClick={canBeCanceled(selectedNFE) ? cancelClick : undefined}
				/>
			),
		},
		{
			id: 2,
			title: 'DANFE',
			content: <Danfe notaId={nfe.id} />,
		},
		{
			id: 3,
			title: 'Carta de Correção',
			content: nfe.correctionNumber > 0 ? <CCPdf notaId={nfe.id} /> : <DefaultCorrectionContent />,
		},
	]

	const getDefaultView = (nfe: NotaFiscalModel) => getViews(nfe)[0]

	return (
		<>
			<div id='nfe-view-loader' className='loader-container nfe-view-loader'>
				<div className='lds-ring'>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
			{selectedNFE ? (
				<div className='issue-detail-container'>
					<NavigatorHeader
						nfe={selectedNFE}
						views={getViews(selectedNFE)}
						changeView={changeView}
					/>
					{view && <IssueView {...view} />}
				</div>
			) : (
				<DefaultNotaFiscalContent />
			)}
		</>
	)
}

export { NotaFiscalDetailPage }
