import { useEffect, useRef, useState } from 'react'
import { useFormik } from 'formik'
import { YupSchemas } from '../../../../services/utils/yupSchemas/YupSchemas'
import { FontAwesomeIcon } from '../../../../components/icon/FontAwesomeIcon'
import { useLoader } from '../../../components/providers/loaderProvider/LoaderProvider'
import { CreateCorrectionModel } from '../models/CorrectionModel'
import { CorrectionService } from '../../../../services/correction/correctionService'
import { notifySuccess } from '../../../../components/toasts/toasts'

const inits: CreateCorrectionModel = {
	text: '',
}

const CorrectionModal = (props: { saleId: number; onEmission: () => void }) => {
	const { saleId, onEmission } = props
	const setLoading = useLoader()
	const [schema] = useState(YupSchemas.correctionCreate)
	const [initValues] = useState<CreateCorrectionModel>(inits)
	const closeModal = useRef<HTMLButtonElement>(null)
	const [lastText, setLastText] = useState<string>('')

	const formik = useFormik({
		initialValues: initValues,
		validationSchema: schema,
		onSubmit: (correction) => cleanMasks(correction),
	})

	useEffect(() => {
		getLastCorrection(saleId)
	}, [saleId])

	const getLastCorrection = async (saleId: number) => {
		try {
			const correction = await CorrectionService.getWithoutErrors(saleId)
			setLastText(correction.text)
		} catch (e) {
			setLastText('')
		}
	}

	const create = async (payload: CreateCorrectionModel) => {
		await CorrectionService.create(payload)
		getLastCorrection(saleId)
		onEmission()
	}

	const cleanMasks = async (correction: CreateCorrectionModel) => {
		const cleanMasks: CreateCorrectionModel = {
			...correction,
			saleId,
		}
		setLoading?.(true)
		await create(cleanMasks)
			.then(() => {
				notifySuccess('Carta de correção cadastrada com sucesso.')
				formik.resetForm()
				closeModal?.current?.click()
			})
			.catch(() => setLoading?.(false))
		setLoading?.(false)
	}

	return (
		<>
			<div className='modal-header'>
				<p className='title'>Corrigir nota fiscal</p>
				<button ref={closeModal} type='button' className='iconButton' data-bs-dismiss='modal'>
					<FontAwesomeIcon iconCode='x' />
				</button>
			</div>
			<div className='modal-body'>
				<form onSubmit={formik.handleSubmit}>
					<div className='form-container'>
						<label htmlFor='name' className='default-label'>
							Condições de uso
						</label>
						<textarea
							className='form-control'
							id='condicoes-uso'
							name='condicoes-uso'
							readOnly
							rows={6}
							value='A Carta de Correção é disciplinada pelo § 1º-A do
								art. 7º do Convênio S/N, de 15 de dezembro de 1970
								e pode ser utilizada para regularização de erro
								ocorrido na emissão de documento fiscal, desde que
								o erro não esteja relacionado com: I - as variáveis
								que determinam o valor do imposto tais como: base
								de cálculo, alíquota, diferença de preço, quantidade,
								valor da operação ou da prestação; II - a correção de
								dados cadastrais que implique mudança do
								remetente ou do destinatário; III - a data de emissão
								ou de saída.'
						/>
						{lastText && (
							<>
								<label htmlFor='complement' className='default-label'>
									Última correção
								</label>
								<textarea
									className='form-control'
									id='last-text'
									name='last-text'
									rows={5}
									readOnly
									onBlur={formik.handleBlur}
									value={lastText}
								/>
							</>
						)}
						<label htmlFor='complement' className='required default-label'>
							Correção
						</label>
						<textarea
							className='form-control'
							id='text'
							name='text'
							rows={5}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.text}
						/>
					</div>

					<div className='form-submit'>
						<button type='button' className='default-button danger' data-bs-dismiss='modal'>
							Cancelar
						</button>
						<button className='default-button tertiary' type='submit'>
							Cadastrar
						</button>
					</div>
				</form>
			</div>
		</>
	)
}
export { CorrectionModal }
