import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { PageHeader } from '../../../components/pageHeader/PageHeader'
import { StepStack } from '../../../components/stepStack/StepStack'
import { ProductService } from '../../../services/product/productService'
import { useAsideModal } from '../../components/providers/asideModalProvider/AsideModalProvider'
import { useAside } from '../../components/providers/asideProvider/AsideProvider'
import { useLoader } from '../../components/providers/loaderProvider/LoaderProvider'
import { useNotaFiscal } from '../../components/providers/notaFiscalProvider/NotaFiscalProvider'
import { ProductCard } from '../../modules/product/components/ProductCard'
import { ProductInput } from '../../modules/product/components/ProductInput'
import { ProductsCest } from '../../modules/product/components/ProductsCest'
import { ProductModel } from '../../modules/product/models/ProductModel'
import { DefaultProductCestContent } from '../../modules/product/components/DefaultProductCestContent'
import { useFooter } from '../../components/providers/footerProvider/FooterProvider'
import { ProductFooter } from '../../modules/product/components/ProductFooter'
import { ProductListCard } from '../../modules/product/components/ProductListCard'
import { useIssuerConfig } from '../../components/providers/issuerConfigProvider/IssuerConfigProvider'

const ProductsPage = () => {
	const { isLoading, data } = useQuery('items', () => ProductService.list())
	const setLoading = useLoader()
	const navigate = useNavigate()
	const { setRightAsideContent, setLeftAsideContent } = useAside()
	const { updateState, toggleModal } = useAsideModal()
	const { setFooterContent } = useFooter()
	const { nota, updateNota } = useNotaFiscal()
	const { config, updateConfig } = useIssuerConfig()
	const [items, setItems] = useState<ProductModel[]>([])
	const [products, setProducts] = useState<ProductModel[]>(nota?.items || [])
	const [showImages, setShowImages] = useState<boolean>()

	useEffect(() => {
		setLeftAsideContent?.(<StepStack />)
	}, [])

	useEffect(() => {
		config?.productsShowImages && setShowImages(config.productsShowImages)
	}, [config])

	useEffect(() => {
		if (products.length > 0) {
			setRightAsideContent?.(
				<ProductsCest
					{...{
						id: 'kt_drawer_aside_toggle',
						products,
						removeHandler: removeSelectedProduct,
						editHandler: setProductQuantity,
					}}
				/>
			)
			const totalValue = products.reduce(
				(previousValue, currentValue) => previousValue + currentValue.total,
				0
			)
			setFooterContent?.(
				<ProductFooter
					{...{ goNext: true, goBack: true, totalValue, navigateNext, navigateBack }}
				/>
			)
			return
		}
		setRightAsideContent?.(<DefaultProductCestContent />)
		setFooterContent?.(
			<ProductFooter {...{ goNext: false, goBack: true, totalValue: 0, navigateBack }} />
		)
	}, [products])

	useEffect(() => {
		setLoading && setLoading(isLoading)
	}, [isLoading])

	useEffect(() => {
		data &&
			setItems(
				data.sort((p1: ProductModel, p2: ProductModel) =>
					p1.description.localeCompare(p2.description)
				)
			)
	}, [data])

	const setProductQuantity = (product: ProductModel) => {
		updateState?.({
			anchor: 'right',
			asideModalContent: <ProductInput {...{ product, clickHandler: selectProduct }} />,
			isOpen: true,
		})
		toggleModal?.(true)
	}

	const selectProduct = (product: ProductModel) => {
		toggleModal?.(false)
		const state = [...products]
		const p = state.find((p: ProductModel) => p.id === product.id)
		if (p?.price) {
			p.quantity = product.quantity
			p.total = p.price * p.quantity
			setProducts(state)
			return
		}
		setProducts((prev: ProductModel[]) => [...prev, product])
	}

	const removeSelectedProduct = (id: number) => {
		const state = [...products]
		setProducts(state.filter((p: ProductModel) => p.id !== id))
	}

	const changeProductsExhibition = () => {
		const newValue = !showImages
		updateConfig?.({ productsShowImages: newValue })
		setShowImages(newValue)
	}

	const navigateNext = () => {
		updateNota?.({ items: products })
		navigate('/formaDePagamento')
	}

	const navigateBack = () => {
		navigate('/transacao')
	}

	return (
		<div className='page-content row'>
			<div className='form-check form-switch form-switch-lg form-check-custom form-check-solid form-check-lg'>
				<label className='form-check-label'>
					<p className='secondary'>Exibição em lista</p>
				</label>
				<input
					className='form-check-input'
					type='checkbox'
					value=''
					checked={!showImages}
					id='flexSwitchDefault'
					onChange={changeProductsExhibition}
				/>
			</div>

			<PageHeader title='Adicione itens à nota' />

			{showImages ? (
				<div className='products-container-images'>
					{items.map((p: ProductModel) => (
						<ProductCard {...{ product: p, clickHandler: setProductQuantity }} />
					))}
				</div>
			) : (
				<div className='products-container-list'>
					{items.map((p: ProductModel) => (
						<ProductListCard {...{ product: p, clickHandler: setProductQuantity }} />
					))}
				</div>
			)}
		</div>
	)
}

export { ProductsPage }
