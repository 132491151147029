import { ProductModel } from '../../app/modules/product/models/ProductModel'
import { baseApi } from '../api/base'

const productApi = baseApi('/products')

const list = async (): Promise<ProductModel[]> => {
	const res = (await productApi.get('')).data.data
	return res
	// return res.map((p: ProductModel) =>
	// 	p.basePrice !== undefined ? { ...p, price: p.basePrice } : { ...p }
	// )
}

const get = async (id: number): Promise<ProductModel> => {
	return productApi.get(id.toString())
}

export const ProductService = {
	list,
	get,
}
