import { PageHeader } from '../../../components/pageHeader/PageHeader'
import { ACNFAgro } from '../../../components/acnfAgro/ACNFAgro'
import { Button as MUIButton } from '@mui/material'

const VerififyEmailPage = () => {
	return (
		<div className='page-content'>
			<ACNFAgro />
			<PageHeader title={'Por favor, verifique seu email antes de usar o emissor.'} />
			<MUIButton
				onClick={() => window.location.reload()}
				variant='contained'
				color='primary'
				size='large'
				style={{ boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)' }}
			>
				JÁ CONFIRMEI MEU EMAIL
			</MUIButton>
		</div>
	)
}

export { VerififyEmailPage }
