import { FontAwesomeIcon } from '../../../../components/icon/FontAwesomeIcon'

const DefaultCorrectionContent = () => {
	return (
		<div className='defaultNFEContent'>
			<FontAwesomeIcon iconCode='fa-solid fa-envelope' style='solid' />
			<p className='secondary'>Nenhuma carta de correção emitida</p>
		</div>
	)
}

export { DefaultCorrectionContent }
