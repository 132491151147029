export const trimAllValues: any = (data: any) => {
	if (data === null) return null

	if (typeof data === 'string') return data.trim()

	if (Array.isArray(data)) {
		return data.map((value) => trimAllValues(value))
	}

	if (typeof data === 'object') {
		return Object.keys({ ...data }).reduce((acc: any, curr) => {
			acc[curr] = trimAllValues(data[curr])
			return acc
		}, {})
	}

	return data
}
