export interface DefaultButtonProps {
	id?: string
	type?: 'button' | 'reset' | 'submit'
	children: React.ReactNode
	size?: 'large'
	styles: 'primary' | 'secondary' | 'tertiary' | 'danger'
	disabled?: boolean
	hidden?: boolean
	clickHandler?: () => void
}

const DefaultButton = (props: DefaultButtonProps) => {
	const { id, type = 'button', children, size, styles, disabled, hidden, clickHandler } = props

	const classes = `${size} ${styles} ${disabled ? 'disabled' : ''} ${hidden ? 'hidden' : ''}`

	return (
		<button
			id={id}
			type={type}
			onClick={clickHandler}
			className={`default-button ${classes}`}
			disabled={disabled}
		>
			{children}
		</button>
	)
}

export { DefaultButton }
