import { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useLayout } from '../../../../_metronic/layout/core/LayoutProvider'

interface FooterConfig {
	display: boolean
}

interface setFooterContentFunction {
	(content: ReactNode): void
}

interface FooterProviderProps {
	footerContent: ReactNode | null
	setFooterContent: setFooterContentFunction | null
}

const FooterContext = createContext<FooterProviderProps>({
	footerContent: null,
	setFooterContent: null,
})

const FooterProvider: FC = ({ children }) => {
	const location = useLocation()
	const { config, setLayout } = useLayout()
	const [footerContent, setFooterContent] = useState<ReactNode>()
	// const [config, setConfig] = useState<FooterConfig>({ display: false })

	const getDefaultFooterDisplayPerRoute = () => {
		const footerDisplayPerRoute = new Map<string, FooterConfig>()
		footerDisplayPerRoute.set('/transacao', { display: true })
		footerDisplayPerRoute.set('/adicionarItems', { display: true })
		footerDisplayPerRoute.set('/formaDePagamento', { display: true })
		footerDisplayPerRoute.set('/selecionarProdutor', { display: true })
		footerDisplayPerRoute.set('/selecionarDestinatario', { display: true })
		footerDisplayPerRoute.set('/selecionarTransportadora', { display: true })
		footerDisplayPerRoute.set('/detalhesDaNota', { display: true })
		footerDisplayPerRoute.set('/revisao', { display: true })
		footerDisplayPerRoute.set('/concluido', { display: true })
		footerDisplayPerRoute.set('/planos', { display: true })
		return footerDisplayPerRoute
	}

	const getDisplayConfigForCurrentRoute = () => {
		return getDefaultFooterDisplayPerRoute().get(location.pathname)
	}

	const updateFooter = (display: boolean) => {
		setLayout({
			footer: {
				...config.footer,
				display,
			},
		})
	}

	const initCurrentRouteFooterDisplayConfig = () => {
		const currentDisplay = getDisplayConfigForCurrentRoute()
		if (!currentDisplay) {
			updateFooter(false)
			return
		}
		updateFooter(currentDisplay.display)
	}

	useEffect(() => {
		initCurrentRouteFooterDisplayConfig()
	}, [location])

	const value = {
		footerContent,
		setFooterContent,
	}

	return <FooterContext.Provider value={value}>{children}</FooterContext.Provider>
}

export { FooterProvider }

export function useFooter() {
	return useContext(FooterContext)
}
