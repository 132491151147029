import { createContext, FC, ReactNode, useContext, useState } from 'react'

export interface BasicModalValues {
	basicModalContent: ReactNode | undefined
	isOpen: boolean | undefined
}

interface updateModalStateFunction {
	(values: BasicModalValues): void
}

interface BasicModalProviderProps {
	values: BasicModalValues | undefined
	updateState: updateModalStateFunction | null
	toggleModal: ((open: boolean) => void) | null
}

const BasicModalContext = createContext<BasicModalProviderProps>({
	values: undefined,
	updateState: null,
	toggleModal: null,
})

const BasicModalProvider: FC = ({ children }) => {
	const [values, setValues] = useState<BasicModalValues>()

	const updateState: updateModalStateFunction = (values) => {
		setValues((prev: BasicModalValues | undefined) => {
			return { ...prev, ...values }
		})
	}

	const toggleModal = (isOpen: boolean) => {
		updateState({ isOpen } as BasicModalValues)
	}

	const value = {
		values,
		updateState,
		toggleModal,
	}

	return <BasicModalContext.Provider value={value}>{children}</BasicModalContext.Provider>
}

export { BasicModalProvider }

export function useBasicModal() {
	return useContext(BasicModalContext)
}
