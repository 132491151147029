import qs from 'qs'

export interface DefaultParams {
	page?: number
	per_page?: number
	sort_field?: string
	sort_order?: string
}

export interface EntityFilterParams {
	person: {
		name?: string
	}
}

export const paramSerializer = (params: unknown) => qs.stringify(params, { allowDots: true })
