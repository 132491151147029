import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { IssuerModel } from '../../modules/producer/models/IssuerModel'
import { ProducerService } from '../../../services/producer/producerService'
import { PersonButton } from '../../../components/buttons/PersonButton'
import { useAside } from '../../components/providers/asideProvider/AsideProvider'
import { StepStack } from '../../../components/stepStack/StepStack'
import { PageHeader } from '../../../components/pageHeader/PageHeader'
import { useNotaFiscal } from '../../components/providers/notaFiscalProvider/NotaFiscalProvider'
import { ClientModel } from '../../modules/client/models/ClientModel'
import { TransporterModel } from '../../modules/transporter/models/TransporterModel'
import { useFooter } from '../../components/providers/footerProvider/FooterProvider'
import { DefaultFooterContent } from '../../../components/footer/DefaultFooterContent'
import { EntityFilterParams } from '../../../services/api/params'
import { Search } from '../../../components/search/Search'
import { TextLoader } from '../../../components/smallLoader/TextLoader'

const ProducerPage = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const { setLeftAsideContent } = useAside()
	const { setFooterContent } = useFooter()
	const { updateNota, nota } = useNotaFiscal()
	const { isLoading, data } = useQuery('producer', () => ProducerService.list())
	const [filters, setFilters] = useState<EntityFilterParams>({ person: { name: '' } })
	const [issuerList, setIssuerList] = useState<IssuerModel[]>([])

	const goBack = () => navigate('/formaDePagamento', { state: { from: location.pathname } })

	useEffect(() => {
		setLeftAsideContent?.(<StepStack />)
		setFooterContent?.(<DefaultFooterContent backClick={goBack} goBack={true} />)
	}, [])

	//useEffect(() => setLoading?.(isLoading), [isLoading])

	const fillAndGoToNextStep = (entity: IssuerModel | ClientModel | TransporterModel) => {
		updateNota?.({ issuer: entity as IssuerModel })
		navigate('/selecionarDestinatario', { state: { from: location.pathname } })
	}

	useEffect(() => data && setIssuerList(data), [data])

	const updateName = (name: string) => {
		setFilters({ person: { name: name } })
	}

	const filterTest = (name: string, filter: string) =>
		name.toLowerCase().includes(filter.toLowerCase() || '')

	const filteredList = issuerList.filter((i: IssuerModel) =>
		filterTest(i.person.name || '', filters.person.name || '')
	)

	return (
		<div className='person-button-container'>
			<PageHeader title='Selecione o produtor' />
			<Search onChangeHandler={updateName} />
			{issuerList.length > 0 ? (
				filteredList.length > 0 ? (
					filteredList.map((producer: IssuerModel) => (
						<PersonButton
							onClick={fillAndGoToNextStep}
							entity={producer}
							isSelected={nota?.issuer?.id === producer.id}
						/>
					))
				) : (
					<p className='primary'>Nenhum resultado.</p>
				)
			) : (
				<TextLoader text='Carregando produtores' position='bottom' />
			)}
		</div>
	)
}

export { ProducerPage }
