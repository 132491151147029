import { FontAwesomeIcon } from '../../../../components/icon/FontAwesomeIcon'

const DefaultNotaFiscalContent = () => {
	return (
		<div className='defaultNFEContent'>
			<FontAwesomeIcon iconCode='file-invoice-dollar' style='solid' />
			<p className='secondary'>Selecione uma nota para visualizá-la.</p>
		</div>
	)
}

export { DefaultNotaFiscalContent }
