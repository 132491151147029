import { ReactNode, useEffect, useState } from 'react'
import {
	DefaultFooterContent,
	DefaultfooterContentProps,
} from '../../../../components/footer/DefaultFooterContent'
import { moneyFormatter } from '../../../../utils/moneyFormatter'

interface ProductFooterProps {
	totalValue: number
	goNext?: boolean
	goBack?: boolean
	navigateNext?: () => void
	navigateBack?: () => void
}

const ProductFooter = (props: ProductFooterProps) => {
	const { totalValue, goNext, goBack, navigateBack, navigateNext } = props
	const [content, setContent] = useState<ReactNode>()
	const [config, setConfig] = useState<DefaultfooterContentProps>({})

	useEffect(() => {
		setContent(
			<div>
				<p className='primary'>Valor total</p>
				<p className='title'>{moneyFormatter.format(totalValue)}</p>
			</div>
		)
	}, [totalValue])

	useEffect(() => {
		setConfig({
			goBack,
			goNext,
			nextClick: navigateNext,
			backClick: navigateBack,
			content,
		})
	}, [content])

	return <DefaultFooterContent {...config} />
}

export { ProductFooter }
