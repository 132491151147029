import { FontAwesomeIcon } from '../icon/FontAwesomeIcon'

interface IconButtonProps {
	iconCode: string
	style?: 'solid' | 'regular' | 'ligth' | 'thin' | 'duotone'
	id?: string
	clickHandler?: () => void
}

const IconButton = (props: IconButtonProps) => {
	const { iconCode, clickHandler, style, id } = props

	return (
		<button id={id} className='iconButton' onClick={clickHandler}>
			<FontAwesomeIcon iconCode={iconCode} style={style || 'regular'} />
		</button>
	)
}

export { IconButton }
