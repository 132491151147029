import { useFooter } from '../../app/components/providers/footerProvider/FooterProvider'
import { useLayout } from '../../_metronic/layout/core'

const Footer = () => {
	const { config } = useLayout()
	const { footerContent } = useFooter()

	return (
		<div
			className={`footer-wrapper ${config.aside.display ? 'aside-enabled' : ''} ${
				config.rightAside.display ? 'aside-right-enabled' : ''
			} ${!config.footer.display ? 'footer-disabled' : ''}
			`}
		>
			<div className='footer-component'>{footerContent}</div>
		</div>
	)
}

export { Footer }
