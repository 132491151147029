import axios from 'axios'
import environment from '../../app/config/environment'
import { keysToCamel } from '../utils/caseConverter'
import { showError, showSuccess } from '../utils/feedbackPopups/popups'
import authLocalStorage from './authLocalStorage'
import { trimAllValues } from './trimAllValues'

const baseApiUrl = environment.getBaseApiUrl()
const fullApiUrl = environment.getFullApiUrl()

const baseApi = (url: string, apiUrl?: string, showErrors?: boolean) => {
	const httpClient = axios.create({
		baseURL: apiUrl ? `${baseApiUrl}${apiUrl}${url}` : `${fullApiUrl}${url}`,
	})

	httpClient.interceptors.request.use(trimAllValues)

	httpClient.interceptors.request.use(async (config) => {
		config.headers.Authorization = `Bearer ${authLocalStorage.getAccessToken()}`
		return config
	})

	httpClient.interceptors.response.use((response) => keysToCamel(response))
	//httpClient.interceptors.request.use((request) => keysToSnake(request))

	if (showErrors) {
		httpClient.interceptors.response.use(
			(response) => {
				showSuccess(response)
				return response
			},
			(error) => {
				showError(error)
				return Promise.reject(error)
			}
		)
	}

	return httpClient
}

export { baseApi }
