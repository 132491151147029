import { useLocation, useNavigate } from 'react-router-dom'
import { PageHeader } from '../../../components/pageHeader/PageHeader'
import { PageTitle } from '../../../_metronic/layout/core'
import { UserIcon } from '../../../components/userIcon/UserIcon'
import { ACNFAgro } from '../../../components/acnfAgro/ACNFAgro'
import { useAuth0 } from '@auth0/auth0-react'
import { Button as MUIButton } from '@mui/material'
import { useAsideModal } from '../../components/providers/asideModalProvider/AsideModalProvider'
import { ConfigModal } from '../../../components/configModal/ConfigModal'
import { PlanInfo } from '../../../components/planInfo/PlanInfo'

const Homepage = () => {
	const { toggleModal, updateState: updateAsideModal } = useAsideModal()
	const navigate = useNavigate()
	const location = useLocation()
	const { user } = useAuth0()
	//const { setAsideModalContent, setAsideModalTitle } = useAsideModal()

	const openConfigModal = () => {
		updateAsideModal?.({
			anchor: 'right',
			asideModalContent: <ConfigModal />,
			isOpen: true,
		})
		toggleModal?.(true)
	}

	const goToNextStep = () => {
		navigate('/transacao')
	}

	const goToNotaFiscalDetail = () => {
		navigate('/detalhesDaNota', { state: { from: location.pathname } })
	}

	// const configIssuer = () => {
	// 	setAsideModalTitle?.('Configurações')
	// 	setAsideModalContent?.(<IssuerConfig />)
	// }

	const isUserAbleToIssue =
		user?.app_metadata?.plan?.type !== 'FREE' && !user?.app_metadata?.deny_access_on_hold

	return (
		<div className='page-content'>
			<UserIcon username={user?.name || ''} clickHandler={openConfigModal} />
			<PageTitle breadcrumbs={[]}>Yes</PageTitle>
			<ACNFAgro />
			<PageHeader title={'Escolha uma das opções abaixo:'} />
			<MUIButton
				disabled={!isUserAbleToIssue}
				onClick={goToNextStep}
				variant='contained'
				color='primary'
				size='large'
				style={{ boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)' }}
			>
				EMITIR NOTA
			</MUIButton>
			<div className='row'>
				<MUIButton
					onClick={goToNotaFiscalDetail}
					variant='contained'
					color='secondary'
					size='large'
				>
					NOTAS EMITIDAS
				</MUIButton>
			</div>
			<PlanInfo />
		</div>
	)
}

export { Homepage }
