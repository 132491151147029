import { ReactNode, useEffect, useState } from 'react'
import {
	DefaultFooterContent,
	DefaultfooterContentProps,
} from '../../../../components/footer/DefaultFooterContent'
import { moneyFormatter } from '../../../../utils/moneyFormatter'

interface PaymentFooterProps {
	goNext?: boolean
	goBack?: boolean
	navigateNext?: () => void
	navigateBack?: () => void
	totalValue: number
}

const PaymentFooter = (props: PaymentFooterProps) => {
	const { goNext, goBack, navigateBack, navigateNext, totalValue } = props
	const [content, setContent] = useState<ReactNode>()
	const [config, setConfig] = useState<DefaultfooterContentProps>({})

	useEffect(() => {
		setContent(
			<div>
				<p className='primary'>Valor total</p>
				<p className='title'>{moneyFormatter.format(totalValue)}</p>
			</div>
		)
	}, [totalValue])

	useEffect(() => {
		setConfig({
			goBack,
			goNext,
			nextClick: navigateNext,
			backClick: navigateBack,
			content,
		})
	}, [content])

	return <DefaultFooterContent {...config} />
}

export { PaymentFooter }
