import { AxiosInstance } from 'axios'
import { AcronymModel } from '../../models/acronym/AcronymModel'
import { baseApi } from '../api/base'

const acronymApi: AxiosInstance = baseApi('/acronyms')

const list = async (): Promise<AcronymModel[]> => {
	return (await acronymApi.get('')).data
}

export const AcronymService = {
	list,
}
