import { createContext, useContext, useEffect, useState } from 'react'

interface IssuerConfig {
	productsShowImages: boolean | null
}

interface UpdateConfigFunction {
	(payload: Partial<IssuerConfig>): void
}

interface IssuerConfigProviderProps {
	config: Partial<IssuerConfig> | null
	updateConfig: UpdateConfigFunction | null
}

const IssuerConfigContext = createContext<IssuerConfigProviderProps>({
	config: null,
	updateConfig: null,
})

const IssuerConfigProvider: React.FC = ({ children }) => {
	const [config, setConfig] = useState<Partial<IssuerConfig> | null>(null)

	useEffect(() => {
		const uconfig = localStorage.getItem('uconfig')
		if (uconfig) {
			setConfig(JSON.parse(uconfig))
		}
	}, [])

	useEffect(() => {
		localStorage.setItem('uconfig', JSON.stringify(config))
	}, [config])

	const updateConfig: UpdateConfigFunction = (payload: Partial<IssuerConfig>) => {
		setConfig({ ...config, ...payload })
	}

	const value = {
		config,
		updateConfig,
	}

	return <IssuerConfigContext.Provider value={value}>{children}</IssuerConfigContext.Provider>
}

export { IssuerConfigProvider }

export function useIssuerConfig() {
	return useContext(IssuerConfigContext)
}
