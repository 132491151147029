import { Card } from '../../../../components/cards/Card'
import { ProductModel } from '../models/ProductModel'

interface SelectedProductCard {
	id?: string
	product: ProductModel
	editHandler?: (product: ProductModel) => void
	removeHandler?: (id: number) => void
}

const SelectedProductCard = (props: SelectedProductCard) => {
	const { id, product, removeHandler, editHandler } = props
	const { description, quantity, unit, price } = product

	const editProduct = () => {
		editHandler?.(product)
	}

	const removeProduct = () => {
		removeHandler?.(product.id)
	}

	const data = {
		id,
		name: description,
		description: `${quantity.toFixed(4).replace('.', ',')} ${unit.description}`,
		total: price! * quantity,
		clickHandler: editProduct,
		removeHandler: removeProduct,
	}

	return <Card {...data} />
}

export { SelectedProductCard }
