import { createTheme } from '@mui/material'

export const theme = createTheme({
	palette: {
		primary: { main: '#6cae17', contrastText: '#fff' },
		secondary: { main: '#364454', contrastText: '#fff' },
		action: {
			disabled: '#9d9d9d',
		},
		text: { primary: '#fff' },
	},
	shape: {
		borderRadius: 6,
	},
	typography: {
		fontSize: 15,
		button: {
			fontWeight: 700,
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					':disabled': {
						backgroundColor: '#2b3643',
					},
				},
				sizeLarge: {
					width: '19vw',
					height: '4.5vw',
					fontSize: '0.8vw',
				},
				sizeMedium: {
					width: '9vw',
					height: '3.3vw',
					fontSize: '0.6vw',
				},
			},
		},
	},
})
