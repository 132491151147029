import { DefaultBasicModal, DefaultBasicModalProps } from '../defaultBasicModal/DefaultBasicModal'

const CancelNfeModal = (props: { cancelHandler?: () => void }) => {
	const { cancelHandler } = props

	const config: DefaultBasicModalProps = {
		title: 'Confirmar',
		description: 'Tem certeza de que deseja cancelar esta nota fiscal?',
		primaryButtonText: 'Confimar',
		primaryClick: cancelHandler,
		secondaryButtonText: 'Cancelar',
	}

	return <DefaultBasicModal {...config} />
}

export { CancelNfeModal }
