import {
	NotaFiscalIssueModel,
	NotaFiscalListModel,
	NotaFiscalModel,
} from '../../app/modules/notaFiscal/models/NotaFiscalModels'
import { PaymentModel } from '../../app/modules/payment/models/PaymentModel'
import { ProductModel } from '../../app/modules/product/models/ProductModel'
import { baseApi } from '../api/base'
import { DefaultParams, paramSerializer } from '../api/params'

const notaFiscalApi = baseApi('/sales', '', true)

export interface NotaFiscalFilterParams extends DefaultParams {
	invoice: {
		series?: number
		number?: number
	}
}

const list = async (params: NotaFiscalFilterParams): Promise<NotaFiscalListModel[]> => {
	const res = await notaFiscalApi.get('', { paramsSerializer: paramSerializer, params })
	return res.data.data
}

const get = async (id: number): Promise<NotaFiscalModel> => {
	return (await notaFiscalApi.get(id.toString())).data
}

const create = async (invoice: NotaFiscalIssueModel): Promise<NotaFiscalModel> => {
	const payload = {
		cfop: invoice.cfop,
		client_id: invoice.client.id,
		issuer_id: invoice.issuer.id,
		items: invoice.items.map((i: ProductModel) => ({
			product_id: i.id,
			quantity: i.quantity,
			price: i.price,
		})),
		payments: invoice.payments.map((p: PaymentModel) => ({
			method_id: p.method.id,
			value: p.value,
		})),
		transporter_id: invoice.transporter?.id,
		transporter_plate: invoice.transporterPlate,
		freight_type: invoice.freightType,
		transporter_volume_qt: invoice.transporterVolumeQt,
		transporter_sp: invoice.transporterSp,
		infCpl: invoice.infCpl,
	}
	return (await notaFiscalApi.post('', payload)).data
}

const cancel = async (id: number) => {
	await notaFiscalApi.put(`${id.toString()}/cancel`)
}

const getDANFEUrl = async (saleId: number): Promise<string> =>
	(await notaFiscalApi.get(`${saleId}/danfe?content_disposition=inline`)).data.url

export const NotaFiscalService = {
	list,
	get,
	create,
	cancel,
	getDANFEUrl,
}
