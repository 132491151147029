import { PersonRole } from '../../../../constants/PersonRole'
import { ClientModel } from '../../client/models/ClientModel'
import { IssuerModel } from '../../producer/models/IssuerModel'
import { TransporterModel } from '../../transporter/models/TransporterModel'
import { NotaFiscalIssueModel } from '../models/NotaFiscalModels'
import { NFECard, NFECardProps } from './NFECard'

interface NFEDetailProps {
	nfe: NotaFiscalIssueModel | Partial<NotaFiscalIssueModel>
	editable?: boolean
}

//** List of NFECards */
const NFEDetail = (props: NFEDetailProps) => {
	const { nfe, editable } = props
	const { issuer, client, transporter, transporterPlate, items, infCpl } = nfe

	function getPersonInfo(model: IssuerModel | ClientModel | TransporterModel | null) {
		if (!model) return
		const person = model.person
		return person.role === PersonRole.COMPANY
			? { name: person.company?.fancyName, document: person.company?.document }
			: { name: person?.name, document: person.individual?.document }
	}

	const issuerCard: NFECardProps | null = !issuer
		? null
		: {
				title: 'Produtor',
				type: 'default',
				...getPersonInfo(issuer),
		  }
	const clientCard: NFECardProps | null = !client
		? null
		: {
				title: 'Destinatário',
				type: 'default',
				...getPersonInfo(client),
		  }
	const transporterCard: NFECardProps | null = !transporter?.id
		? null
		: {
				title: 'Transportadora',
				type: 'default',
				plate: transporterPlate,
				...getPersonInfo(transporter),
		  }
	const itemsCard: NFECardProps | null = !items
		? null
		: {
				title: 'Itens',
				type: 'itemList',
				items: items,
				editable,
		  }

	const additionalInfoCard: NFECardProps | null = !infCpl
		? null
		: {
				title: 'Informação complementar',
				type: 'simple',
				info: infCpl,
				editable,
		  }

	// This defines the order that the elements will be displayed to the user
	const cards = [issuerCard, clientCard, transporterCard, itemsCard, additionalInfoCard]

	return (
		<div className='cardContainer'>
			{cards.map((card: NFECardProps | null) => {
				if (!card) return
				return (
					<div key={card.title}>
						<NFECard {...card} />
					</div>
				)
			})}
		</div>
	)
}

export { NFEDetail }
