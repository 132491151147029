import { useNavigate } from 'react-router-dom'
import { Button as MUIButton } from '@mui/material'
import { NotaFiscalService } from '../../../../services/notaFiscal/notaFiscalService'
import { useLoader } from '../../../components/providers/loaderProvider/LoaderProvider'
import { useNotaFiscal } from '../../../components/providers/notaFiscalProvider/NotaFiscalProvider'
import { NotaFiscalIssueModel } from '../../notaFiscal/models/NotaFiscalModels'

interface ReviewFooterProps {
	nextClick?: () => void
	backClick?: () => void
}

const ReviewFooter = (props: ReviewFooterProps) => {
	const { nota, clearNota, updateLastIssuedNota } = useNotaFiscal()
	const navigate = useNavigate()
	const setLoading = useLoader()
	const { nextClick, backClick } = props

	const navigateNext = async () => {
		if (nota) {
			setLoading?.(true)
			await NotaFiscalService.create(nota as NotaFiscalIssueModel)
				.then((res) => {
					setLoading?.(false)
					updateLastIssuedNota?.(res.id)
					clearNota?.()
					navigate('/concluido')
				})
				.catch(() => {
					setLoading?.(false)
				})
		}
	}

	return (
		<div className='default-footer-content'>
			<MUIButton onClick={backClick} variant='contained' color='secondary' size='medium'>
				VOLTAR
			</MUIButton>
			<MUIButton onClick={navigateNext} variant='contained' color='primary' size='medium'>
				CONCLUIR
			</MUIButton>
		</div>
	)
}

export { ReviewFooter }
