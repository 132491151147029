import * as Yup from 'yup'
import { Masks } from '../masks/masks'

export const YupSchemas = {
	personSchema: Yup.object({
		name: Yup.string().max(60, 'Máximo de 60 caracteres').required('Campo obrigatório'),
		fancyName: Yup.string().max(60, 'Máximo de 60 caracteres'),
		role: Yup.number(),
		document: Yup.string()
			.test('test-valid-document', 'Documento inválido', (document: string | undefined) =>
				Masks.validateDocument(document)
			)
			.required('Campo obrigatório'),
		stateRegistry: Yup.string(),
		email: Yup.string().email('Email inválido').required('Campo obrigatório'),
		phoneNumber: Yup.string().max(20, 'Máximo de 20 caracteres'),
		mobileNumber: Yup.string().max(20, 'Máximo de 20 caracteres'),
		postalCode: Yup.string().required('Campo obrigatório'),
		country: Yup.string().max(60, 'Máximo de 60 caracteres').required('Campo obrigatório'),
		state: Yup.string().max(60, 'Máximo de 60 caracteres').required('Campo obrigatório'),
		city: Yup.string().max(60, 'Máximo de 60 caracteres').required('Campo obrigatório'),
		district: Yup.string().max(60, 'Máximo de 60 caracteres').required('Campo obrigatório'),
		street: Yup.string().max(60, 'Máximo de 60 caracteres').required('Campo obrigatório'),
		streetNumber: Yup.number().max(9999999, 'Máximo de 7 caracteres').required('Campo obrigatório'),
		complement: Yup.string().max(100, 'Máximo de 100 caracteres'),
	}),
	nfeFilters: Yup.object({
		series: Yup.number(),
		number: Yup.number(),
	}),
	correctionCreate: Yup.object({
		text: Yup.string(),
	}),
}
