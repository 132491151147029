import { useEffect, useRef, useState } from 'react'
import { moneyFormatter } from '../../../../utils/moneyFormatter'
import { ProductModel } from '../models/ProductModel'
import { Button as MUIButton } from '@mui/material'

interface ProductInputProps {
	product: ProductModel
	clickHandler: (product: ProductModel) => void
}

const ProductInput = (props: ProductInputProps) => {
	const { product, clickHandler } = props
	const [quantity, setQuantity] = useState('')
	const [price, setPrice] = useState('')
	const input = useRef<HTMLInputElement>(null)
	const confirm = useRef<HTMLButtonElement>(null)

	useEffect(() => {
		document.addEventListener(
			'keydown',
			(event) => {
				if (event.code === 'Enter' || event.code === 'NumpadEnter') {
					confirm?.current?.click()
				}
			},
			false
		)
	})

	useEffect(() => {
		setPrice(product.basePrice.toString().replace('.', ','))
		input?.current?.focus()
	}, [props])

	const addProduct = () => {
		product.price = parseFloat(price.replace(',', '.'))
		product.quantity = parseFloat(quantity.replace(',', '.'))
		product.total = product.price * product.quantity
		setQuantity('')
		clickHandler?.(product)
	}

	const validateInput = (input: string) => input.match(/^(\d*,{0,1}\d{0,4}$)/)

	const changeQuantity = (e: React.FormEvent<HTMLInputElement>) => {
		const newQuantity = e.currentTarget.value
		if (validateInput(newQuantity)) setQuantity(newQuantity)
	}

	const changePrice = (e: React.FormEvent<HTMLInputElement>) => {
		const newPrice = e.currentTarget.value
		if (validateInput(newPrice)) setPrice(newPrice)
	}

	const canAddProduct = () => {
		if (!quantity || parseFloat(quantity.replace(',', '.')) === 0) return false
		if (!price || parseFloat(price.replace(',', '.')) === 0) return false
		return true
	}

	return (
		<div className='input-container'>
			<div className='product-quantity-input'>
				<label className='default-label'>
					Quantidade ({product.unit.description})
					<input
						className='default-input'
						ref={input}
						autoFocus
						value={quantity}
						placeholder='0,0'
						onChange={changeQuantity}
					/>
				</label>
				<label className='default-label'>
					Preço (R$)
					<input className='default-input' value={price} onChange={changePrice} />
				</label>
			</div>
			<div className='product-total-display'>
				<p className='secondary'>Total do item</p>
				<p className='primary'>
					{moneyFormatter.format(
						quantity && price
							? parseFloat(price.replace(',', '.')) * parseFloat(quantity.replace(',', '.'))
							: 0
					)}
				</p>
			</div>
			<MUIButton
				onClick={addProduct}
				disabled={!canAddProduct()}
				variant='contained'
				color='primary'
				size='medium'
			>
				INCLUIR ITEM
			</MUIButton>
		</div>
	)
}

export { ProductInput }
