import { useAuth0 } from '@auth0/auth0-react'
import { DefaultButton } from '../../../../components/buttons/DefaultButton'
import { CancelNfeModal } from '../../../../components/CancelNfeModal/CancelNfeModal'
import { useBasicModal } from '../../../components/providers/basicModalProvider/BasicModalProvider'
import moment from 'moment'
import { NotaFiscalModel } from '../models/NotaFiscalModels'

interface NFEFooterProps {
	cloneClick?: () => void
	cancelClick?: () => void
	nfe?: NotaFiscalModel
}

const NFEFooter = (props: NFEFooterProps) => {
	const { cloneClick, cancelClick } = props
	const { updateState } = useBasicModal()
	const { user } = useAuth0()

	const confirmCancel = () => {
		updateState?.({
			isOpen: true,
			basicModalContent: <CancelNfeModal cancelHandler={cancelClick} />,
		})
	}

	const cancelDisabled = cancelClick ? false : true
	const cloneDisabled = (cloneClick ? false : true) || user?.app_metadata?.plan?.type === 'FREE'
	const daysSinceOpen = moment().diff(moment(props.nfe?.openDate), 'days')
	console.log(daysSinceOpen)
	const correctDisabled = user?.app_metadata?.plan?.type === 'FREE' || daysSinceOpen > 30

	return (
		<div className='default-footer-content center'>
			<button
				data-bs-target='#modal_resgistration'
				data-bs-toggle='modal'
				className={`default-button primary ${correctDisabled ? 'disabled' : ''}`}
				disabled={correctDisabled}
			>
				CORRIGIR
			</button>
			<DefaultButton styles='tertiary' disabled={cloneDisabled} clickHandler={cloneClick}>
				CLONAR
			</DefaultButton>
			<DefaultButton styles='danger' disabled={cancelDisabled} clickHandler={confirmCancel}>
				CANCELAR
			</DefaultButton>
		</div>
	)
}

export { NFEFooter }
