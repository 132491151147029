import { useEffect } from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import { createContext, Dispatch, FC, SetStateAction } from 'react'

const LoaderContext = createContext<Dispatch<SetStateAction<boolean>> | undefined>(undefined)

export const LoaderProvider: FC = ({ children }) => {
	const [count, setCount] = useState(0)
	const [loading, setLoading] = useState(false)
	const show = count > 0

	useEffect(() => {
		if (!loading) {
			return
		}

		if (setCount) {
			setCount((prev) => {
				return prev + 1
			})
		}

		return () => {
			if (setCount) {
				setCount((prev) => {
					return prev - 1
				})
			}
		}
	}, [setCount, loading])

	useEffect(() => {
		const loader = document.getElementById('loader-container')

		// Show SplashScreen
		if (loader && show) {
			loader.classList.remove('loader-hidden')

			return () => {
				loader.classList.add('loader-hidden')
			}
		}

		// Hide SplashScreen
		// let timeout: number
		if (loader && !show) {
			loader.classList.add('loader-hidden')
			// timeout = window.setTimeout(() => {
			// 	loader.classList.add('loader-hidden')
			// }, 3000)
		}

		// return () => {
		// 	clearTimeout(timeout)
		// }
	}, [show])

	return <LoaderContext.Provider value={setLoading}>{children}</LoaderContext.Provider>
}

export function useLoader() {
	return useContext(LoaderContext)
}
