import { FontAwesomeIcon } from '../../../components/icon/FontAwesomeIcon'

const CompletedIssue = () => {
	return (
		<div className='default-cest-content'>
			<FontAwesomeIcon style='solid' iconCode='circle-check' />
			<p className='primary'>A nota foi emitida e enviada com sucesso</p>
		</div>
	)
}

export { CompletedIssue }
