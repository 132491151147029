import { ProductModel } from '../models/ProductModel'
import { SelectedProductCard } from './SelectedProductCard'

const ProductsCest = (props: {
	id?: string
	products: ProductModel[]
	removeHandler: (id: number) => void
	editHandler: (product: ProductModel) => void
}) => {
	const { id, products, removeHandler, editHandler } = props
	return (
		<div className='cest-container'>
			{products.map((p: ProductModel) => (
				<SelectedProductCard {...{ id, product: p, removeHandler, editHandler }} />
			))}
		</div>
	)
}

export { ProductsCest }
