import { useBasicModal } from '../../app/components/providers/basicModalProvider/BasicModalProvider'
import { ClientModel } from '../../app/modules/client/models/ClientModel'
import { IssuerModel } from '../../app/modules/producer/models/IssuerModel'
import { TransporterModel } from '../../app/modules/transporter/models/TransporterModel'
import { PersonRole } from '../../constants/PersonRole'
import { Masks } from '../../services/utils/masks/masks'
import { DefaultBasicModal, DefaultBasicModalProps } from '../defaultBasicModal/DefaultBasicModal'
import { FontAwesomeIcon } from '../icon/FontAwesomeIcon'
import { TrashButton } from './TrashButton'

interface PersonButtonProps {
	entity: IssuerModel | ClientModel | TransporterModel
	onClick: (entity: IssuerModel | ClientModel | TransporterModel) => void
	asideToggleId?: string
	handleDelete?: (id: number) => void
	isSelected?: boolean
}

/** Button for selecting Producer, Client or Transporter */
const PersonButton = (props: PersonButtonProps) => {
	const { onClick, asideToggleId, handleDelete, entity, isSelected } = props
	const { name, addresses } = props.entity.person
	const { updateState } = useBasicModal()

	function getPersonInfo(model: IssuerModel | ClientModel | TransporterModel | null) {
		if (!model) return
		const person = model.person
		return person.role === PersonRole.COMPANY ? person.company : person.individual
	}

	const { document, stateRegistry } = getPersonInfo(props.entity) || {}

	const select = () => {
		onClick(props.entity)
	}

	const deletePerson = () => {
		handleDelete?.(entity.id)
	}

	const confirmDelete = () => {
		const config: DefaultBasicModalProps = {
			title: 'Excluir',
			description: `Tem certeza de que deseja excluir?`,
			primaryButtonText: 'Confirmar',
			primaryClick: deletePerson,
			secondaryButtonText: 'Cancelar',
		}
		updateState?.({
			isOpen: true,
			basicModalContent: <DefaultBasicModal {...config} />,
		})
	}

	const deletable = !!handleDelete

	return (
		<div className={`person-button ${isSelected ? 'selected' : ''}`} id={asideToggleId}>
			<button className='person-select-button ' onClick={select}>
				<div className='left'>
					<p className='primary'>{name ?? 'Não informado'}</p>
					<p className='secondary'>{document ? Masks.formatDocument(document) : 'Não informado'}</p>
				</div>
				<div>
					<p className='secondary'>{`${addresses[0]?.cityLocation.city ?? 'Não informado'} / ${
						addresses[0]?.cityLocation.state ?? 'Não informado'
					}`}</p>
					<p className='secondary'>
						IE nº {stateRegistry ? Masks.formatIE(stateRegistry) : 'Não informado'}
					</p>
				</div>

				<FontAwesomeIcon style='solid' iconCode={props.entity.person.role ? 'building' : 'user'} />
			</button>
			{deletable && (
				<div className='person-delete-button'>
					<TrashButton clickHandler={confirmDelete} />
				</div>
			)}
		</div>
	)
}

export { PersonButton }
