import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { NotaFiscalService } from '../../services/notaFiscal/notaFiscalService'
import { useLoader } from '../../app/components/providers/loaderProvider/LoaderProvider'
import { pdfjs } from 'react-pdf'
import { notifyError } from '../toasts/toasts'
import { TextLoader } from '../smallLoader/TextLoader'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface DanfeProps {
	notaId: number
	cancelClick?: () => void
	cloneClick?: () => void
}

const Danfe = (props: DanfeProps) => {
	const { notaId } = props
	const [pdf, setPdf] = useState<any>()
	const setLoading = useLoader()

	useEffect(() => {
		setLoading?.(true)
	}, [])

	const { isLoading, error, data } = useQuery(
		'DANFEUrl',
		() => NotaFiscalService.getDANFEUrl(notaId),
		{ retry: false, cacheTime: 0 }
	)

	useEffect(() => {
		data && setPdf(data)
		stopLoading()
	}, [data])

	useEffect(() => {
		if (error) {
			stopLoading()
			notifyError('Erro ao carregar a DANFE.')
		}
	}, [error])

	const stopLoading = () => setLoading?.(false)

	const danfeLoader = <TextLoader text='Carregando DANFE' position='bottom' />

	return pdf ? (
		<embed type='application/pdf' src={pdf} id='pdfDocument' width='100%' height='100%' />
	) : (
		danfeLoader
	)
	//<object id='pdf' ref={ref} data={pdf} type='application/pdf' width='100%' height='100%' />
	// <Document
	// 	className='danfe'
	// 	file={pdf}
	// 	loading={danfeLoader}
	// 	noData={danfeLoader}
	// 	onLoadSuccess={stopLoading}
	// 	onLoadError={stopLoading}
	// >
	// 	<Page pageNumber={1} />
	// </Document>
}

export { Danfe }
