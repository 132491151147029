import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { AsideModalProvider } from './components/providers/asideModalProvider/AsideModalProvider'
import { AsideProvider } from './components/providers/asideProvider/AsideProvider'
import { BasicModalProvider } from './components/providers/basicModalProvider/BasicModalProvider'
import { FooterProvider } from './components/providers/footerProvider/FooterProvider'
import { IssuerConfigProvider } from './components/providers/issuerConfigProvider/IssuerConfigProvider'
import { LoaderProvider } from './components/providers/loaderProvider/LoaderProvider'
import { NotaFiscalProvider } from './components/providers/notaFiscalProvider/NotaFiscalProvider'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { RegistrationModalProvider } from './components/providers/registrationModalProvider/RegistrationModalProvider'

const App = () => {
	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<LayoutProvider>
				<LoaderProvider>
					<IssuerConfigProvider>
						<NotaFiscalProvider>
							<FooterProvider>
								<AsideProvider>
									<AsideModalProvider>
										<RegistrationModalProvider>
											<BasicModalProvider>
												<Outlet />
												<MasterInit />
												<ToastContainer
													position='top-right'
													autoClose={5000}
													hideProgressBar={false}
													newestOnTop={false}
													closeOnClick
													rtl={false}
													pauseOnFocusLoss
													draggable
													pauseOnHover
												/>
											</BasicModalProvider>
										</RegistrationModalProvider>
									</AsideModalProvider>
								</AsideProvider>
							</FooterProvider>
						</NotaFiscalProvider>
					</IssuerConfigProvider>
				</LoaderProvider>
			</LayoutProvider>
		</Suspense>
	)
}

export { App }
