const AUTH_KEY = 'auth'
const ID_TOKEN_KEY = 'auth:idToken'

const hasToken = () => {
	const token = localStorage.getItem(AUTH_KEY)
	return token !== '' && token !== undefined && token !== null
}

const getAccessToken = () => localStorage.getItem(AUTH_KEY)

const setAccessToken = (accessToken: string) => localStorage.setItem(AUTH_KEY, accessToken)

const getIdToken = () => localStorage.getItem(ID_TOKEN_KEY)

const setIdToken = (idToken: string) => localStorage.setItem(ID_TOKEN_KEY, idToken)

const removeAccessToken = () => localStorage.setItem(AUTH_KEY, '')

export default {
	hasToken,
	getAccessToken,
	setAccessToken,
	removeAccessToken,
	getIdToken,
	setIdToken,
}
