import { ReactNode, useEffect } from 'react'
import { useFooter } from '../../../components/providers/footerProvider/FooterProvider'

export interface IssueViewProps {
	id: number
	title?: string
	content?: ReactNode
	footer?: ReactNode
}

const IssueView = (props: IssueViewProps) => {
	const { content, footer } = props
	const { setFooterContent } = useFooter()

	useEffect(() => {
		footer && setFooterContent?.(footer)
	}, [footer])

	return <div className='issue-view-container'>{content}</div>
}

export { IssueView }
