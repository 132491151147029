export const resourceMessage = new Map()
resourceMessage.set('accounts', {
	post: 'Conta criada!',
	put: 'Conta atualizada!',
	delete: 'Conta excluída!',
})
resourceMessage.set('users', {
	post: 'Usuário criado!',
	put: 'Usuário atualizado!',
	delete: 'Usuário excluído!',
})
resourceMessage.set('products', {
	post: 'Produto criado!',
	put: 'Produto atualizado!',
	delete: 'Produto excluído!',
})
resourceMessage.set('payment_methods', {
	post: 'Método de pagamento criado!',
	put: 'Método de pagamento atualizado!',
	delete: 'Método de pagamento excluído!',
})
resourceMessage.set('config', {
	put: 'Configuração atualizada!',
})
resourceMessage.set('taxes', {
	post: 'Imposto criado!',
	put: 'Imposto atualizado!',
	delete: 'Imposto excluído!',
})
resourceMessage.set('issuers', {
	post: 'Emitente criado!',
	put: 'Emitente atualizado!',
	delete: 'Emitente excluído!',
})
resourceMessage.set('clients', {
	post: 'Destinatário criado!',
	put: 'Destinatário atualizado!',
	delete: 'Destinatário excluído!',
})
resourceMessage.set('transporters', {
	post: 'Transportadora criada!',
	put: 'Transportadora atualizada!',
	delete: 'Transportadora excluída!',
})
resourceMessage.set('certificates', {
	post: 'Certificado criado!',
	put: 'Certificado atualizado!',
	delete: 'Certificado excluído!',
})
