import { createContext, FC, ReactNode, useContext, useState } from 'react'

interface updateAsideModalStateFunction {
	(values: AsideModalValues): void
}

export interface AsideModalValues {
	asideModalContent: ReactNode | undefined
	anchor: 'left' | 'right' | undefined
	isOpen: boolean | undefined
}

interface AsideModalProviderProps {
	values: AsideModalValues | undefined
	updateState: updateAsideModalStateFunction | null
	toggleModal: ((open: boolean) => void) | null
}

const AsideModalContext = createContext<AsideModalProviderProps>({
	values: undefined,
	updateState: null,
	toggleModal: null,
})

const AsideModalProvider: FC = ({ children }) => {
	const [values, setValues] = useState<AsideModalValues>()

	const updateState: updateAsideModalStateFunction = (values) => {
		setValues((prev: AsideModalValues | undefined) => {
			return { ...prev, ...values }
		})
	}

	const toggleModal = (isOpen: boolean) => {
		updateState({ isOpen } as AsideModalValues)
	}

	const value = {
		values,
		updateState,
		toggleModal,
	}

	return <AsideModalContext.Provider value={value}>{children}</AsideModalContext.Provider>
}

export { AsideModalProvider }

export function useAsideModal() {
	return useContext(AsideModalContext)
}
