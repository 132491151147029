export enum NotaFiscalStatus {
	INICIAL = 'Inicial',
	PENDENTE = 'Pendente',
	ORDENADA = 'Pendente',
	CONFIRMADA = 'Confirmada',
	CANCELADA = 'Cancelada',
	RETORNADA = 'Retornada',
	RENEGOCIADA = 'Renegociada',
	UNKNOWN = 'Pendente',
}

const status = [
	NotaFiscalStatus.INICIAL,
	NotaFiscalStatus.PENDENTE,
	NotaFiscalStatus.ORDENADA,
	NotaFiscalStatus.CONFIRMADA,
	NotaFiscalStatus.CANCELADA,
	NotaFiscalStatus.RETORNADA,
	NotaFiscalStatus.RENEGOCIADA,
	NotaFiscalStatus.UNKNOWN,
]

export const getStatusByNumber = (num: number) => status[num]
