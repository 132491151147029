import { useEffect } from 'react'
import { Danfe } from '../../../components/danfe/Danfe'
import { PageHeader } from '../../../components/pageHeader/PageHeader'
import { StepStack } from '../../../components/stepStack/StepStack'
import { useAside } from '../../components/providers/asideProvider/AsideProvider'
import { useFooter } from '../../components/providers/footerProvider/FooterProvider'
import { useNotaFiscal } from '../../components/providers/notaFiscalProvider/NotaFiscalProvider'
import { CompletedFooter } from '../../modules/completed/CompletedFooter'
import { CompletedIssue } from '../../modules/completed/CompletedIssue'

const CompletedPage = () => {
	const { setLeftAsideContent, setRightAsideContent } = useAside()
	const { lastIssuedNotaId } = useNotaFiscal()
	const { setFooterContent } = useFooter()

	useEffect(() => {
		setLeftAsideContent?.(<StepStack />)
		setRightAsideContent?.(<CompletedIssue />)
		setFooterContent?.(<CompletedFooter {...{ printDanfeClick, shareClick }} />)
	}, [])

	const printDanfeClick = () => {
		console.log(lastIssuedNotaId)
	}

	const shareClick = () => {
		return
	}

	return (
		<div className='page-content'>
			{lastIssuedNotaId ? <Danfe notaId={lastIssuedNotaId} /> : null}
		</div>
	)
}

export { CompletedPage }
