/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { App } from '../App'
import { ErrorsPage } from '../pages/ErrorsPage/ErrorsPage'
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import { LayoutSplashScreen } from '../../_metronic/layout/core'
import environment from '../config/environment'
import authLocalStorage from '../../services/api/authLocalStorage'
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */

const AppRoutes: FC = () => {
	const { isLoading, error, getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

	useEffect(() => {
		;(async () => {
			if (getAccessTokenSilently) {
				const token = await getAccessTokenSilently({
					scope: 'openid profile email',
					audience: environment.getAudience(),
				}).catch(async () => {
					const a = await getAccessTokenWithPopup({
						scope: 'openid profile email',
						audience: environment.getAudience(),
					})
					return a
				})
				authLocalStorage.setAccessToken(token)
			}
		})()
	}, [getAccessTokenSilently])

	if (isLoading) {
		return <LayoutSplashScreen />
	}

	if (error) {
		return <div>Erro</div>
	}

	const ProtectedRoutes = withAuthenticationRequired(PrivateRoutes, {
		onRedirecting: () => <LayoutSplashScreen />,
		loginOptions: {
			ui_locales: 'pt-BR',
		},
	})

	return (
		<Routes>
			<Route element={<App />}>
				<Route path='/*' element={<ProtectedRoutes />} />
				<Route path='error/*' element={<ErrorsPage />} />
			</Route>
		</Routes>
	)
}

export { AppRoutes }
