interface ValidatorFuncion {
	(target: string | undefined): boolean
}

interface FormatterFuncion {
	(target: string): string
}

const clean = (value: string) => {
	if (!value) return ''

	return value.replace(/\D/g, '')
}

const validateCpf: ValidatorFuncion = (cpf: string | undefined) => {
	if (!cpf) return false
	cpf = cpf.replace(/[^\d]+/g, '')
	if (cpf == '') return false
	if (
		cpf.length != 11 ||
		cpf == '00000000000' ||
		cpf == '11111111111' ||
		cpf == '22222222222' ||
		cpf == '33333333333' ||
		cpf == '44444444444' ||
		cpf == '55555555555' ||
		cpf == '66666666666' ||
		cpf == '77777777777' ||
		cpf == '88888888888' ||
		cpf == '99999999999'
	)
		return false
	let add = 0
	for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i)
	let rev = 11 - (add % 11)
	if (rev == 10 || rev == 11) rev = 0
	if (rev != parseInt(cpf.charAt(9))) return false
	add = 0
	for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i)
	rev = 11 - (add % 11)
	if (rev == 10 || rev == 11) rev = 0
	if (rev != parseInt(cpf.charAt(10))) return false
	return true
}

const formatCpf: FormatterFuncion = (cpf: string) => {
	if (!cpf || cpf.length === 0) return ''
	return cpf
		.replace(/\D/g, '')
		.replace(/(\d{3})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d{1,2})/, '$1-$2')
		.replace(/(-\d{2})\d+?$/, '$1')
}

const validateCnpj: ValidatorFuncion = (cnpj: string | undefined) => {
	if (!cnpj) return false
	cnpj = cnpj.replace(/[^\d]+/g, '')
	if (cnpj == '') return false
	if (cnpj.length != 14) return false

	// Elimina CNPJs invalidos conhecidos
	if (
		cnpj == '00000000000000' ||
		cnpj == '11111111111111' ||
		cnpj == '22222222222222' ||
		cnpj == '33333333333333' ||
		cnpj == '44444444444444' ||
		cnpj == '55555555555555' ||
		cnpj == '66666666666666' ||
		cnpj == '77777777777777' ||
		cnpj == '88888888888888' ||
		cnpj == '99999999999999'
	)
		return false

	// Valida DVs
	let tamanho = cnpj.length - 2
	let numeros = cnpj.substring(0, tamanho)
	const digitos = cnpj.substring(tamanho)
	let soma = 0
	let pos = tamanho - 7
	for (let i = tamanho; i >= 1; i--) {
		soma += parseInt(numeros.charAt(tamanho - i)) * pos--
		if (pos < 2) pos = 9
	}
	let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
	if (resultado != parseInt(digitos.charAt(0))) return false

	tamanho = tamanho + 1
	numeros = cnpj.substring(0, tamanho)
	soma = 0
	pos = tamanho - 7
	for (let i = tamanho; i >= 1; i--) {
		soma += parseInt(numeros.charAt(tamanho - i)) * pos--
		if (pos < 2) pos = 9
	}
	resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
	if (resultado != parseInt(digitos.charAt(1))) return false

	return true
}

const formatCnpj: FormatterFuncion = (cnpj: string) => {
	if (!cnpj || cnpj.length === 0) return ''
	return cnpj
		.replace(/\D/g, '')
		.replace(/(\d{2})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d{1,2})/, '$1/$2')
		.replace(/(\d{4})(\d)/, '$1-$2')
}

const formatDocument: FormatterFuncion = (document: string) => {
	if (!document) return ''
	if (document.length === 11) return formatCpf(document)
	return formatCnpj(document)
}

const validateDocument: ValidatorFuncion = (document: string | undefined) => {
	if (!document) return false
	document = clean(document)
	if (document.length === 11) return validateCpf(document)
	return validateCnpj(document)
}

const validatePlate: ValidatorFuncion = (plate: string | undefined) => {
	if (!plate) return false
	const mercosul = /([A-Za-z]{3}[0-9]{1}[A-Za-z]{1}[0-9]{2})/
	const normal = /([A-Za-z]{3}[0-9]{4})/
	if (mercosul.exec(plate) || normal.exec(plate)) return true
	return false
}

const formatPlate: FormatterFuncion = (plate: string) => {
	return plate.toUpperCase()
}

const validatePhone: ValidatorFuncion = (phone: string | undefined) => {
	if (!phone) return false
	return true
}

const formatPhone: FormatterFuncion = (phone: string) => {
	if (!phone) return ''
	return phone
		.replace(/\D/g, '')
		.replace(/^(\d{2})(\d)/g, '($1) $2')
		.replace(/(\d)(\d{4})$/, '$1-$2')
}

const validateEmail: ValidatorFuncion = (email: string | undefined) => {
	if (!email) return false
	const mask =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	if (mask.exec(email)) return true
	return false
}

const formatEmail: FormatterFuncion = (email: string) => {
	return email.toLowerCase()
}

const formatIE: FormatterFuncion = (ie: string) => {
	if (!ie || ie.length === 0) return ''
	return ie.replace(/(\d{9})(\d)/, '$1-$2')
}

const formatCEP: FormatterFuncion = (cep: string) => {
	if (!cep) return ''
	return cep
		.replace(/\D/g, '')
		.replace(/(\d{2})(\d)/, '$1.$2')
		.replace(/(\d)(\d{3})$/, '$1-$2')
}

const formatCfop: FormatterFuncion = (cfop: string) => {
	if (!cfop) return ''
	return cfop.replace(/(\d{1})(\d)/, '$1.$2')
}

const noSpecialCharacters: FormatterFuncion = (value: string) => {
	if (!value) return ''
	return value.replace(/[^a-zA-Z0-9 ]/g, '')
}

export const Masks = {
	validateCpf,
	formatCpf,
	validateCnpj,
	validateDocument,
	formatCnpj,
	formatDocument,
	validatePlate,
	formatPlate,
	validatePhone,
	formatPhone,
	validateEmail,
	formatEmail,
	formatIE,
	formatCEP,
	formatCfop,
	clean,
	noSpecialCharacters,
}
