import { moneyFormatter } from '../../../../utils/moneyFormatter'
import { ProductModel } from '../models/ProductModel'

interface ProductCardProps {
	product: ProductModel
	id?: string
	clickHandler?: (p: ProductModel) => void
}

const ProductCard = (props: ProductCardProps) => {
	const { id, product, clickHandler } = props
	const { description: name, basePrice, images } = product

	const click = () => {
		clickHandler?.(product)
	}

	return (
		<button id={id} onClick={click} className='product-card'>
			<img className='product-card-image' src={images[0].href} />
			<p className='secondary'>{name.toLowerCase()}</p>
			{<p className='title'>{moneyFormatter.format(basePrice)}</p>}
		</button>
	)
}

export { ProductCard }
