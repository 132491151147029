import { TransporterModel } from '../../app/modules/transporter/models/TransporterModel'
import { CreatePersonModel } from '../../models/person/CreatePersonModel'
import { PersonModel } from '../../models/person/PersonModel'
import { baseApi } from '../api/base'
import { keysToSnake } from '../utils/caseConverter'

const transporterApi = baseApi('/transporters')

const list = async (): Promise<TransporterModel[]> => {
	return (await transporterApi.get('')).data.data
}

const get = async (id: number): Promise<TransporterModel> => {
	return await transporterApi.get(id.toString())
}

const create = async (person: CreatePersonModel): Promise<CreatePersonModel> => {
	const {
		city,
		complement,
		country,
		district,
		document,
		email,
		fancyName,
		mobileNumber,
		name,
		phoneNumber,
		postalCode,
		role,
		state,
		stateRegistry,
		street,
		streetNumber,
		stateRegistryIsento,
	} = person

	const newTransporter: Partial<PersonModel> = {
		role,
		name,
		phoneNumber,
		mobileNumber,
		email,
	}

	role == 1
		? (newTransporter.company = {
				document,
				stateRegistry: stateRegistryIsento ? 'ISENTO' : stateRegistry,
				fancyName,
		  })
		: (newTransporter.individual = {
				document,
				stateRegistry,
		  })

	newTransporter.addresses = [
		{
			street,
			streetNumber,
			district,
			postalCode,
			complement,
			cityLocation: {
				city,
				state,
				country,
			},
		},
	]

	const payload = {
		person: newTransporter,
	}

	return (await transporterApi.post('', keysToSnake(payload))).data
}

const del = async (id: number) => {
	return await transporterApi.delete(id.toString())
}

export const TransporterService = {
	list,
	get,
	create,
	del,
}
