import { TrashButton } from '../../../../components/buttons/TrashButton'
import { moneyFormatter } from '../../../../utils/moneyFormatter'
import { useNotaFiscal } from '../../../components/providers/notaFiscalProvider/NotaFiscalProvider'
import { ProductModel } from '../../product/models/ProductModel'

interface ItemDetailProps {
	item: ProductModel
	editable?: boolean
}

const ItemDetail = (props: ItemDetailProps) => {
	const { nota, updateNota } = useNotaFiscal()
	const { item, editable } = props
	const { id, description: name, price, quantity, unit } = item

	const removeItemHandler = () => {
		if (nota?.items) {
			const items = [...nota.items]
			updateNota?.({ items: items.filter((i: ProductModel) => i.id !== id) })
		}
	}

	return (
		<div className='itemDetail'>
			<p className='primary itemName'>{name}</p>
			<div className='item-info'>
				<p className='secondary'>{price && moneyFormatter.format(price)}</p>
				<p className='secondary'>x</p>
				<p className='secondary'>
					{quantity.toFixed(4)} {unit.description}
				</p>
				<p className='primary last'>{price && moneyFormatter.format(price * quantity)}</p>
				{editable && <TrashButton classes='last' clickHandler={removeItemHandler} />}
			</div>
			<hr />
		</div>
	)
}

export { ItemDetail }
