import { AxiosError, AxiosResponse } from 'axios'
import { notifyError, notifySuccess } from '../../../components/toasts/toasts'
import { resourceMessage } from './feedbackMessages'

const getResourceMessageFromResponse = (response: AxiosResponse) => {
	const resource = getResourceNameFromRequestBaseURL(response.config.baseURL)
	return getResourceMessageByCallMethod(resource, response.config.method)
}

const getResourceNameFromRequestBaseURL = (requestBaseURL: string | undefined) =>
	requestBaseURL?.substring(requestBaseURL.indexOf('v1/') + 'v1/'.length)

const getResourceMessageByCallMethod = (resource: string | undefined, method: string | undefined) =>
	resourceMessage.get(resource) && method && resourceMessage.get(resource)[method]

export const showSuccess = (response: AxiosResponse) =>
	notifySuccess(getResourceMessageFromResponse(response))

export const showError = (responseError: AxiosError) =>
	notifyError(
		responseError.response?.data?.message
			? responseError.response?.data?.message
			: responseError.response?.status
			? ' Código do erro ' + responseError.response?.status
			: 'Ops, algo deu errado. Entre em contato com o suporte.'
	)
