import { baseApi } from '../api/base'
import { TransactionCategoryModel } from '../../app/modules/transaction/models/TransactionCategoryModel'

const trasactionApi = baseApi('/taxes/account-cfop-categories')

const list = async (): Promise<TransactionCategoryModel[]> => {
	return (await trasactionApi.get('')).data
}

export const TransactionService = {
	list,
}
