import { useBasicModal } from '../../app/components/providers/basicModalProvider/BasicModalProvider'
import { Button as MUIButton } from '@mui/material'

export interface DefaultBasicModalProps {
	title: string
	description?: string
	primaryClick?: () => void
	secondaryClick?: () => void
	primaryButtonText: string
	secondaryButtonText: string
}

const DefaultBasicModal = (props: DefaultBasicModalProps) => {
	const {
		title,
		description,
		primaryClick,
		secondaryClick,
		primaryButtonText,
		secondaryButtonText,
	} = props
	const { toggleModal } = useBasicModal()

	const onPrimaryClick = () => {
		toggleModal?.(false)
		primaryClick?.()
	}

	const onSecondaryClick = () => {
		toggleModal?.(false)
		secondaryClick?.()
	}

	return (
		<>
			<div className='default-modal-content'>
				<p className='title'>{title}</p>
				<p className='primary'>{description}</p>
			</div>
			<div className='default-modal-footer'>
				<MUIButton onClick={onSecondaryClick} variant='contained' color='secondary' size='medium'>
					{secondaryButtonText}
				</MUIButton>
				<MUIButton onClick={onPrimaryClick} variant='contained' color='primary' size='medium'>
					{primaryButtonText}
				</MUIButton>
			</div>
		</>
	)
}

export { DefaultBasicModal }
