import { IssuerModel } from '../../app/modules/producer/models/IssuerModel'
import { baseApi } from '../api/base'

const producerApi = baseApi('/issuers')

const list = async (): Promise<IssuerModel[]> => {
	return (await producerApi.get('')).data.data
}

const get = async (id: number): Promise<IssuerModel> => {
	return await producerApi.get(id.toString())
}

export const ProducerService = {
	list,
	get,
}
