import { PaymentMethodModel } from '../../app/modules/payment/models/PaymentMethodModel'
import { baseApi } from '../api/base'

const paymentApi = baseApi('/payment_methods')

const list = async (): Promise<PaymentMethodModel[]> => {
	const res = await paymentApi.get('')
	return res.data.data
}

const get = async (id: number): Promise<PaymentMethodModel> => {
	return await paymentApi.get(id.toString())
}

export const PaymentService = {
	list,
	get,
}
