import { useAuth0 } from '@auth0/auth0-react'
import { useBasicModal } from '../../app/components/providers/basicModalProvider/BasicModalProvider'
import { LogoutModal } from '../logoutModal/LogoutModal'
import environment from '../../app/config/environment'
import authLocalStorage from '../../services/api/authLocalStorage'
import { useNavigate } from 'react-router-dom'
import { useAsideModal } from '../../app/components/providers/asideModalProvider/AsideModalProvider'

const ConfigModal = () => {
	const navigate = useNavigate()
	const { updateState } = useBasicModal()
	const { toggleModal } = useAsideModal()
	const { user, logout } = useAuth0()

	const logoutUser = () => {
		authLocalStorage.removeAccessToken()
		logout({ returnTo: environment.getLogoutCallback() })
	}

	const openConfimationModal = () => {
		updateState?.({ isOpen: true, basicModalContent: <LogoutModal logoutHandler={logoutUser} /> })
	}

	const onPlansClick = () => {
		toggleModal?.(false)
		navigate('/planos')
	}

	return (
		<div className='config-modal-container'>
			<div className='config-item-list'>
				<div className='config-item-button' onClick={onPlansClick}>
					Planos
				</div>
				<div className='config-item-button' onClick={openConfimationModal}>
					Sair da conta
				</div>
			</div>
		</div>
	)
}

export { ConfigModal }
