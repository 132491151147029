import { useLocation, useNavigate } from 'react-router-dom'
import { useBasicModal } from '../../app/components/providers/basicModalProvider/BasicModalProvider'
import { useNotaFiscal } from '../../app/components/providers/notaFiscalProvider/NotaFiscalProvider'
import { ACNFAgro } from '../acnfAgro/ACNFAgro'
import { CancelIssueModal } from '../cancelIssueModal/CancelIssueModal'
import { FontAwesomeIcon } from '../icon/FontAwesomeIcon'
import { Step, StepStatus } from './Step'

const StepStack = () => {
	const { updateState } = useBasicModal()
	const { clearNota, steps } = useNotaFiscal()
	const location = useLocation()
	const navigate = useNavigate()

	const currentStep = steps?.find((step) => step.location === location.pathname) || 0
	const currentStepIndex = (currentStep ? steps?.indexOf(currentStep) : 0) || 0

	const cancelIssueHandler = () => {
		clearNota?.()
		navigate('/homepage')
	}

	const setBasicModal = () => {
		updateState?.({
			isOpen: true,
			basicModalContent: <CancelIssueModal cancelHandler={cancelIssueHandler} />,
		})
	}

	const handleNavigate = (stepName: string) => {
		const loc = steps?.find((step) => step.name === stepName)?.location
		loc && navigate(loc)
	}

	return (
		<div className='step-stack'>
			<div className='step-stack-header'>
				<p className='primary '>Emissão de nota</p>
				<button
					type='button'
					className={`iconButton ${location.pathname === '/concluido' && 'hidden'}`}
					onClick={setBasicModal}
				>
					<FontAwesomeIcon iconCode='x' />
				</button>
			</div>
			<div className='step-stack-list'>
				{steps?.map((step) => {
					if (step.hiddenInStack) return
					let status = StepStatus.DONE
					if (steps.indexOf(step) === currentStepIndex) status = StepStatus.SELECTED
					else if (steps.indexOf(step) > currentStepIndex) status = StepStatus.TODO
					return <Step name={step.name} status={status} navigateHandler={handleNavigate} />
				})}
			</div>
			<ACNFAgro />
		</div>
	)
}

export { StepStack }
