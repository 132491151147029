import { DefaultBasicModal, DefaultBasicModalProps } from '../defaultBasicModal/DefaultBasicModal'

const LogoutModal = (props: { logoutHandler: () => void }) => {
	const { logoutHandler } = props

	const config: DefaultBasicModalProps = {
		title: 'Sair',
		description: 'Tem certeza de que deseja sair?',
		primaryButtonText: 'Sim',
		primaryClick: logoutHandler,
		secondaryButtonText: 'Não',
	}

	return <DefaultBasicModal {...config} />
}

export { LogoutModal }
