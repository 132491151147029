import { useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '../icon/FontAwesomeIcon'

export enum StepStatus {
	DONE = 'done',
	SELECTED = 'selected',
	TODO = 'todo',
}

export interface StepProps {
	name: string
	status: StepStatus
	navigateHandler?: (stepName: string) => void
}

const Step = ({ name, status, navigateHandler }: StepProps) => {
	const location = useLocation()
	const iconCode = status === StepStatus.DONE ? 'circle-check' : 'circle'
	const iconStyle = status === StepStatus.SELECTED ? 'solid' : undefined
	const textStyle =
		status === StepStatus.DONE ? 'primary' : status === StepStatus.SELECTED ? 'title' : 'secondary'

	const navigateClick = () => {
		navigateHandler?.(name)
	}

	const navigateCondition = () => status === StepStatus.DONE && location.pathname !== '/concluido'

	return (
		<div className={`step ${status}`}>
			<FontAwesomeIcon style={iconStyle} iconCode={iconCode} />
			<button className='edit-button' onClick={navigateClick} disabled={!navigateCondition()}>
				<p className={textStyle}>{name}</p>
			</button>
		</div>
	)
}

export { Step }
