export interface DefaultLabelProps {
	id?: string
	title?: string
	children: React.ReactNode
}

const DefaultLabel = (props: DefaultLabelProps) => {
	const { id, title, children } = props

	return (
		<label id={id} className='default-label'>
			{title}
			{children}
		</label>
	)
}

export { DefaultLabel }
