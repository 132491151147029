import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { IssuerModel } from '../../modules/producer/models/IssuerModel'
import { useLoader } from '../../components/providers/loaderProvider/LoaderProvider'
import { PersonButton } from '../../../components/buttons/PersonButton'
import { useAside } from '../../components/providers/asideProvider/AsideProvider'
import { StepStack } from '../../../components/stepStack/StepStack'
import { PageHeader } from '../../../components/pageHeader/PageHeader'
import { useNotaFiscal } from '../../components/providers/notaFiscalProvider/NotaFiscalProvider'
import { ClientService } from '../../../services/client/clientService'
import { ClientModel } from '../../modules/client/models/ClientModel'
import { TransporterModel } from '../../modules/transporter/models/TransporterModel'
import { useFooter } from '../../components/providers/footerProvider/FooterProvider'
import { ClientPageFooter } from '../../modules/client/components/ClientPageFooter'
import { useRegistrationModal } from '../../components/providers/registrationModalProvider/RegistrationModalProvider'
import { ClientModal } from '../../modules/client/components/ClientModal'
import { notifyError } from '../../../components/toasts/toasts'
import { Search } from '../../../components/search/Search'
import { EntityFilterParams } from '../../../services/api/params'
import { SmallLoader } from '../../../components/smallLoader/SmallLoader'
import { TextLoader } from '../../../components/smallLoader/TextLoader'

const ClientPage = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const { setFooterContent } = useFooter()
	const { setLeftAsideContent } = useAside()
	const { setModalContent } = useRegistrationModal()
	const { updateNota, nota } = useNotaFiscal()
	const { isLoading, data } = useQuery('client', () => ClientService.list(), { retry: false })
	const [filters, setFilters] = useState<EntityFilterParams>({ person: { name: '' } })
	const [clientList, setClientList] = useState<ClientModel[]>([])
	const setLoading = useLoader()

	const goBack = () => navigate('/selecionarProdutor', { state: { from: location.pathname } })

	const updateList = async (params?: EntityFilterParams) => {
		setClientList([])
		//setLoading?.(true)
		await ClientService.list(params).then((res) => {
			setClientList(res)
		})
		//setLoading?.(false)
	}

	useEffect(() => {
		setLeftAsideContent?.(<StepStack />)
		setFooterContent?.(<ClientPageFooter goBack={true} navigateBack={goBack} />)
		setModalContent?.(<ClientModal updateList={updateList} />)
	}, [])

	//useEffect(() => setLoading?.(isLoading), [isLoading])

	const fillAndGoToNextStep = (entity: IssuerModel | ClientModel | TransporterModel) => {
		updateNota?.({ client: entity as ClientModel })
		navigate('/selecionarTransportadora', { state: { from: location.pathname } })
	}

	const deleteClient = async (id: number) => {
		setLoading?.(true)
		await ClientService.del(id)
			.then(() => {
				updateList()
			})
			.catch(() => {
				notifyError('Não foi possível deletar este destinatário.')
			})
		setLoading?.(false)
	}

	useEffect(() => data && setClientList(data), [data])

	const updateName = (name: string) => {
		setFilters({ person: { name: name } })
	}

	// useEffect(() => {
	// 	updateList(filters)
	// }, [filters])

	const filterTest = (name: string, filter: string) =>
		name.toLowerCase().includes(filter.toLowerCase() || '')

	const filteredList = clientList.filter((c: ClientModel) =>
		filterTest(c.person.name || '', filters.person.name || '')
	)

	return (
		<div className='person-button-container'>
			<PageHeader title='Selecione o destinatário' />
			<Search onChangeHandler={updateName} />
			{clientList.length > 0 ? (
				filteredList.length > 0 ? (
					filteredList.map((client: ClientModel) => (
						<PersonButton
							onClick={fillAndGoToNextStep}
							entity={client}
							handleDelete={deleteClient}
							isSelected={nota?.client?.id === client.id}
						/>
					))
				) : (
					<p className='primary'>Nenhum resultado.</p>
				)
			) : (
				<TextLoader text='Carregando destinatários' position='bottom' />
			)}
		</div>
	)
}

export { ClientPage }
