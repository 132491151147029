import { ChangeEventHandler } from 'react'

export interface DefaultInputProps {
	id?: string
	type?: string
	autoFocus?: boolean
	value?: string | number | readonly string[]
	onChange?: ChangeEventHandler<HTMLInputElement>
	maxLength?: number
}

const DefaultInput = (props: DefaultInputProps) => {
	const { id, type, autoFocus, value, onChange, maxLength } = props

	return (
		<input
			id={id}
			className='default-input'
			type={type}
			autoFocus={autoFocus}
			value={value}
			onChange={onChange}
			maxLength={maxLength}
		/>
	)
}

export { DefaultInput }
