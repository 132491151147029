import { FontAwesomeIcon } from '../../../../components/icon/FontAwesomeIcon'

const DefaultPaymentCestContent = () => {
	return (
		<div className='default-cest-content'>
			<FontAwesomeIcon style='solid' iconCode='cash-register' />
			<p className='secondary'>Selecione a forma de pagamento.</p>
		</div>
	)
}

export { DefaultPaymentCestContent }
