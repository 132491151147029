import { ReactNode } from 'react'

export interface CardButtonProps {
	clickHandler?: () => void
	id?: string
	name: string
	description?: string
	icon?: ReactNode
	styleClasses: 'small' | 'large' | 'large with-description' | 'small homepage'
	isSelected?: boolean
}

const CardButton = (props: CardButtonProps) => {
	const { id, name, description, icon, styleClasses, clickHandler, isSelected } = props
	return (
		<button
			id={id}
			onClick={clickHandler}
			className={`card-button ${styleClasses} ${isSelected ? 'selected' : ''}`}
		>
			{icon}
			<p className='primary'>{name}</p>
			{description && <p className='secondary'>{description}</p>}
		</button>
	)
}

export { CardButton }
