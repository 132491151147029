import { useEffect, useState } from 'react'
import { CardButton, CardButtonProps } from '../../../../components/buttons/CardButton'
import { FontAwesomeIcon } from '../../../../components/icon/FontAwesomeIcon'
import { useNotaFiscal } from '../../../components/providers/notaFiscalProvider/NotaFiscalProvider'
import { TransactionCategoryModel } from '../models/TransactionCategoryModel'

interface TransactionTypeButtonProps {
	id?: string
	value: TransactionCategoryModel
	clickHandler: (t: TransactionCategoryModel) => void
	largeButton: boolean
}

const TransactionTypeButton = (props: TransactionTypeButtonProps) => {
	const { nota } = useNotaFiscal()
	const { id, value, clickHandler, largeButton } = props
	const { name, description, icon, accountCfops } = value
	const [isSelected, setIsSelected] = useState<boolean>()

	useEffect(() => {
		const f = accountCfops.filter((c) => c.code === nota?.cfop)
		if (f.length > 0) return setIsSelected(true)
		setIsSelected(false)
	}, [nota])

	const click = () => {
		clickHandler(value)
	}

	const card: CardButtonProps = {
		id,
		name,
		clickHandler: click,
		description,
		icon: <FontAwesomeIcon style='solid' iconCode={icon} />,
		styleClasses: 'large with-description',
		isSelected,
	}

	return largeButton ? (
		<CardButton {...card} />
	) : (
		<div className='flex justify-center'>
			<button id='kt_drawer_aside_toggle' className='edit-button' onClick={click}>
				<p className='title'>Outras saídas</p>
			</button>
		</div>
	)
}

export { TransactionTypeButton }
