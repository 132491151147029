import { ReactNode } from 'react'
import { Button as MUIButton } from '@mui/material'
import { DefaultButton } from '../buttons/DefaultButton'

export interface DefaultfooterContentProps {
	goNext?: boolean
	goBack?: boolean
	nextClick?: () => void
	backClick?: () => void
	content?: ReactNode
	noHidden?: boolean
}

const DefaultFooterContent = (props: DefaultfooterContentProps) => {
	const { goBack, goNext, backClick, nextClick, content, noHidden } = props

	return (
		<div className='default-footer-content'>
			<MUIButton
				onClick={backClick}
				disabled={!goBack}
				hidden={goBack === undefined}
				variant='contained'
				color='secondary'
				size='medium'
			>
				VOLTAR
			</MUIButton>
			<div className='custom-content'>{content}</div>
			{!noHidden && (
				<MUIButton
					onClick={nextClick}
					disabled={!goNext}
					//hidden={goNext === undefined}
					variant='contained'
					color='primary'
					size='medium'
				>
					AVANÇAR
				</MUIButton>
			)}
		</div>
	)
}

export { DefaultFooterContent }
