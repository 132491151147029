import React, { createContext, ReactNode, useContext, useState } from 'react'
import { NotaFiscalIssueModel } from '../../../modules/notaFiscal/models/NotaFiscalModels'
import { ClientPage } from '../../../pages/ClientPage/ClientPage'
import { CompletedPage } from '../../../pages/CompletedPage/CompletedPage'
import { PaymentPage } from '../../../pages/PaymentPage/PaymentPage'
import { ProducerPage } from '../../../pages/ProducerPage/ProducerPage'
import { ProductsPage } from '../../../pages/ProductsPage/ProductsPage'
import { ReviewPage } from '../../../pages/ReviewPage/ReviewPage'
import { TransactionTypePage } from '../../../pages/TransactionTypePage/TransactionTypePage'
import { TransporterPage } from '../../../pages/TransporterPage/TransporterPage'

export interface UpdateNotaFunction {
	(payload: Partial<NotaFiscalIssueModel>): void
}

export interface GetNextStepFunction {
	(currentStepId: number): string
}

export interface StepI {
	id: number
	name: string
	location: string
	element?: ReactNode
	hiddenInStack?: boolean
}

interface NotaFiscalProviderProps {
	updateNota: UpdateNotaFunction | null
	nota: Partial<NotaFiscalIssueModel> | null
	lastIssuedNotaId: number | null
	updateLastIssuedNota: ((id: number) => void) | null
	clearNota: (() => void) | null
	steps: StepI[] | null
	getNextStepLocation: GetNextStepFunction | null
}

const NotaFiscalContext = createContext<NotaFiscalProviderProps>({
	updateNota: null,
	nota: null,
	lastIssuedNotaId: null,
	updateLastIssuedNota: null,
	clearNota: null,
	steps: null,
	getNextStepLocation: null,
})

const NotaFiscalProvider: React.FC = ({ children }) => {
	const [nota, setNota] = useState<Partial<NotaFiscalIssueModel> | null>(null)
	const [lastIssuedNotaId, setLastIssuedNotaId] = useState<number | null>(null)
	const [currentStepId, setCurrentStepId] = useState<number>(0)

	const steps: StepI[] = [
		{ id: 1, name: 'Tipo de transação', location: '/transacao', element: <TransactionTypePage /> },
		{ id: 2, name: 'Itens', location: '/adicionarItems', element: <ProductsPage /> },
		{ id: 3, name: 'Pagamento', location: '/formaDePagamento', element: <PaymentPage /> },
		{ id: 4, name: 'Produtor', location: '/selecionarProdutor', element: <ProducerPage /> },
		{ id: 5, name: 'Destinatário', location: '/selecionarDestinatario', element: <ClientPage /> },
		{
			id: 6,
			name: 'Transportadora',
			location: '/selecionarTransportadora',
			element: <TransporterPage />,
		},
		{ id: 7, name: 'Resumo', location: '/revisao', element: <ReviewPage /> },
		{
			id: 8,
			name: 'Concluido',
			location: '/concluido',
			element: <CompletedPage />,
			hiddenInStack: true,
		},
	]

	const updateNota: UpdateNotaFunction = (payload) => {
		setNota((prev: Partial<NotaFiscalIssueModel> | null) => {
			if (prev) return { ...prev, ...payload }
			return payload
		})
	}

	const updateLastIssuedNota = (id: number) => {
		setLastIssuedNotaId?.(id)
	}

	const clearNota = () => {
		setNota({})
	}

	const getNextStepLocation: GetNextStepFunction = () => {
		if (currentStepId) {
			const nextStepId = currentStepId + 1
			if (nextStepId <= steps.length) {
				const location = steps.find((s: StepI) => s.id === nextStepId)?.location
				if (location) {
					setCurrentStepId(nextStepId)
					return location
				}
			}
		}
		setCurrentStepId(0)
		return '/homepage'
	}

	const value = {
		updateNota,
		nota,
		clearNota,
		lastIssuedNotaId,
		updateLastIssuedNota,
		steps,
		getNextStepLocation,
	}

	return <NotaFiscalContext.Provider value={value}>{children}</NotaFiscalContext.Provider>
}

export { NotaFiscalProvider }

export function useNotaFiscal() {
	return useContext(NotaFiscalContext)
}
