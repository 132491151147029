import { FontAwesomeIcon } from '../../../../components/icon/FontAwesomeIcon'

const DefaultProductCestContent = () => {
	return (
		<div className='default-cest-content'>
			<FontAwesomeIcon style='solid' iconCode='basket-shopping' />
			<div>
				<p className='secondary'>A nota está vazia.</p>
				<p className='secondary'>Toque nos itens para adicioná-los.</p>
			</div>
		</div>
	)
}

export { DefaultProductCestContent }
