import { useNavigate } from 'react-router-dom'
import { DefaultButton } from '../../../components/buttons/DefaultButton'

interface CompletedFooterProps {
	printDanfeClick: () => void
	shareClick: () => void
}

const CompletedFooter = (props: CompletedFooterProps) => {
	const { printDanfeClick, shareClick } = props
	const navigate = useNavigate()

	const navigateToBeggining = () => {
		navigate('/homepage')
	}

	return (
		<div className='default-footer-content'>
			<div className='side-by-side-buttons'>
				<DefaultButton styles='tertiary' hidden clickHandler={printDanfeClick}>
					IMPRIMIR
				</DefaultButton>
				<DefaultButton styles='tertiary' hidden clickHandler={shareClick}>
					COMPARTILHAR
				</DefaultButton>
			</div>
			<DefaultButton styles='primary' clickHandler={navigateToBeggining}>
				FECHAR
			</DefaultButton>
		</div>
	)
}

export { CompletedFooter }
