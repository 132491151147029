import { ReactNode, useEffect, useState } from 'react'
import {
	DefaultFooterContent,
	DefaultfooterContentProps,
} from '../../../../components/footer/DefaultFooterContent'

interface ClientPageFooterProps {
	goNext?: boolean
	goBack?: boolean
	navigateNext?: () => void
	navigateBack?: () => void
}

const ClientPageFooter = (props: ClientPageFooterProps) => {
	const { goNext, goBack, navigateBack, navigateNext } = props
	const [content, setContent] = useState<ReactNode>()
	const [config, setConfig] = useState<DefaultfooterContentProps>({})

	useEffect(
		() =>
			setContent(
				<button
					data-bs-target='#modal_resgistration'
					data-bs-toggle='modal'
					className='default-button tertiary'
				>
					+ CADASTRAR NOVO
				</button>
			),
		[]
	)

	useEffect(() => {
		const noHidden = true
		setConfig({
			goBack,
			goNext,
			nextClick: navigateNext,
			backClick: navigateBack,
			content,
			noHidden,
		})
	}, [content])

	return <DefaultFooterContent {...config} />
}

export { ClientPageFooter }
