import { moneyFormatter } from '../../../../utils/moneyFormatter'
import { ProductModel } from '../models/ProductModel'

interface ProductListCardProps {
	product: ProductModel
	id?: string
	clickHandler?: (p: ProductModel) => void
}

const ProductListCard = (props: ProductListCardProps) => {
	const { product, id, clickHandler } = props
	const { description, basePrice } = product

	const click = () => {
		clickHandler?.(product)
	}

	return (
		<button id={id} className='product-list-card' onClick={click}>
			<p className='primary'>{description}</p>
			<p className='title'>{moneyFormatter.format(basePrice)}</p>
		</button>
	)
}

export { ProductListCard }
