import { IconButton } from '../../../../components/buttons/IconButton'
import { Masks } from '../../../../services/utils/masks/masks'
import { useAsideModal } from '../../../components/providers/asideModalProvider/AsideModalProvider'
import { ProductModel } from '../../product/models/ProductModel'
import { ItemDetail } from './ItemDetail'

export interface NFECardProps {
	title: string
	type: 'default' | 'itemList' | 'simple'
	name?: string
	document?: string
	plate?: string
	info?: string
	items?: ProductModel[]
	editable?: boolean
}

const NFECard = (props: NFECardProps) => {
	const { title, type, name, document, plate, items, info, editable } = props
	const { toggleModal } = useAsideModal()

	const openModal = () => {
		toggleModal?.(true)
	}

	return (
		<div className='nfe-card'>
			<div className='nfe-card-header'>
				<p className='title'>{title}</p>
				{type === 'simple' && editable && (
					<IconButton style='solid' iconCode='pen-to-square' clickHandler={openModal} />
				)}
			</div>
			{type === 'default' && (
				<div>
					{name && <p className='primary'>{name}</p>}
					{document && (
						<p className='secondary'>
							{Masks.formatDocument(document)}
							{plate && ` - ${Masks.formatPlate(plate)}`}
						</p>
					)}
				</div>
			)}
			{type === 'itemList' && (
				<>
					{items && items.length > 0 ? (
						items.map((item: ProductModel) => {
							return (
								<div key={item.id}>
									<ItemDetail {...{ item, editable }} />
								</div>
							)
						})
					) : (
						<p className='secondary'>Vazio</p>
					)}
				</>
			)}
			{type === 'simple' && (
				<div>
					<p className='primary'>{info}</p>
				</div>
			)}
		</div>
	)
}

export { NFECard }
