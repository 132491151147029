import { FC } from 'react'

const Error500: FC = () => {
	return (
		<>
			<h1 className='fw-bolder fs-4x text-gray-700 mb-10'>Erro</h1>

			<div className='fw-bold fs-3 text-gray-400 mb-15'>
				Algo deu errado! <br /> Por favor tente novamente mais tarde.
			</div>
		</>
	)
}

export { Error500 }
