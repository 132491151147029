import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Countdown from 'react-countdown'
import { addDays } from '../../utils/date'
import { Button } from '@mui/material'

interface Plan {
	type: string
	utc_creation_date: string
	credits: number
	active: boolean
}

const PlanInfo = () => {
	const { user } = useAuth0()
	const [plan, setPlan] = useState<Plan>()

	useEffect(() => {
		setPlan(user?.app_metadata.plan)
	}, [user?.app_metadata.plan])

	const countdownRenderer = ({ days, hours, minutes, seconds, completed }: any) => {
		if (completed) {
			// Render a completed state
			return <span className='font-weight-bold text-danger'>Seu período de testes acabou</span>
		} else {
			// Render a countdown
			if (days > 0) {
				return (
					<span className='font-weight-bold text-success'>
						{days} dias, {hours} horas, {minutes} minutos e {seconds} segundos&nbsp;&nbsp;&nbsp;
					</span>
				)
			} else {
				return (
					<span className='font-weight-bold text-danger'>
						{hours} horas, {minutes} minutos e {seconds} segundos&nbsp;&nbsp;&nbsp;
					</span>
				)
			}
		}
	}

	return (
		<>
			{plan?.type === 'FREE' && (
				<Link to='/planos'>
					<Button variant='contained' color='primary' size='medium' sx={{ marginTop: 5 }}>
						ASSINE JÁ
					</Button>
				</Link>
			)}

			{plan?.type === 'PREPAID' && (
				<Link to='/planos' className='navi-item px-8 cursor-pointer'>
					<div className='font-weight-bolder font-size-base btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2'>
						<p className='text-success'>
							Você possui{' '}
							<span className='font-weight-bolder font-size-base w-auto'>{plan.credits}</span> notas
							restantes &nbsp;&nbsp;&nbsp;
						</p>
					</div>
				</Link>
			)}

			{plan?.type === 'TRIAL' && (
				<Link to='/planos' className='navi-item px-8 cursor-pointer'>
					<p className='primary'>
						<span>Seu período de testes acaba em:&nbsp;&nbsp;&nbsp;</span>
						<Countdown
							date={addDays(plan.utc_creation_date, 7)}
							renderer={countdownRenderer}
							zeroPadTime={2}
							now={() => {
								const today = new Date()
								return today.setHours(today.getHours() + 3)
							}}
						/>
					</p>
				</Link>
			)}

			{plan && !plan.active && (
				<Link to='/planos' className='navi-item px-8 cursor-pointer'>
					<div className='text-danger font-weight-bolder font-size-base btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2'>
						<p>Aguardando confirmação do pagamento</p>
					</div>
				</Link>
			)}
		</>
	)
}

export { PlanInfo }
