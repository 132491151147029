const ACNFAgro = () => {
	return (
		<div className='logo-container'>
			<img alt='ACNF Agro logo' src='/media/logos/logo.svg' className='acnfagro-logo'></img>
			<div id='beta' className='beta'>
				Beta
			</div>
		</div>
	)
}

export { ACNFAgro }
