import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '../../../components/icon/FontAwesomeIcon'
import { PageHeader } from '../../../components/pageHeader/PageHeader'
import { StepStack } from '../../../components/stepStack/StepStack'
import { PaymentService } from '../../../services/payment/paymentService'
import { useAside } from '../../components/providers/asideProvider/AsideProvider'
import { useFooter } from '../../components/providers/footerProvider/FooterProvider'
import { useLoader } from '../../components/providers/loaderProvider/LoaderProvider'
import { useNotaFiscal } from '../../components/providers/notaFiscalProvider/NotaFiscalProvider'
import { DefaultPaymentCestContent } from '../../modules/payment/components/DefaultPaymentCestContent'
import { PaymentCest } from '../../modules/payment/components/PaymentCest'
import { PaymentFooter } from '../../modules/payment/components/PaymentFooter'
import { PaymentMethodButton } from '../../modules/payment/components/PaymentMethodButton'
import { PaymentMethodModel } from '../../modules/payment/models/PaymentMethodModel'
import { PaymentModel } from '../../modules/payment/models/PaymentModel'

const PaymentPage = () => {
	const { isLoading, data } = useQuery('payments', () => PaymentService.list())
	const setLoading = useLoader()
	const navigate = useNavigate()
	const { setLeftAsideContent, setRightAsideContent } = useAside()
	const { setFooterContent } = useFooter()
	const { nota, updateNota } = useNotaFiscal()
	const [items, setItems] = useState<PaymentMethodModel[]>([])
	const [payments, setPayments] = useState<PaymentModel[]>([])

	useEffect(() => {
		setLeftAsideContent?.(<StepStack />)
	}, [])

	useEffect(() => {
		if (payments.length > 0) {
			setRightAsideContent?.(
				<PaymentCest {...{ payments, removeHandler: removeSelectedPayment }} />
			)
			setFooterContent?.(<PaymentFooter {...{ goBack: true, navigateBack, totalValue }} />)
			return
		}
		setRightAsideContent?.(<DefaultPaymentCestContent />)
		setFooterContent?.(<PaymentFooter {...{ goBack: true, navigateBack, totalValue }} />)
	}, [payments, nota])

	useEffect(() => {
		setLoading && setLoading(isLoading)
	}, [isLoading])

	useEffect(() => {
		data && setItems(data)
	}, [data])

	const totalValue = nota?.items?.reduce((prev, current) => prev + current.total, 0) || 0

	// const setAmount = (payment: PaymentModel) => {
	// 	setAsideModalTitle?.(payment.method.name)
	// 	setAsideModalContent?.(<PaymentInput {...{ payment, clickHandler: selectPayment }} />)
	// }

	const selectPayment = (payment: PaymentMethodModel) => {
		const p = {
			id: payment.id,
			value: totalValue,
			method: payment,
		}
		setPayments([p])
		// const state = [...payments]
		// const p = state.find((p: PaymentModel) => p.id === payment.id)
		// if (p) {
		// 	p.value = payment.value
		// 	setPayments(state)
		// 	return
		// }
		// setPayments((prev: PaymentModel[]) => [...prev, payment])
	}

	const removeSelectedPayment = (id: number) => {
		const state = [...payments]
		setPayments(state.filter((p: PaymentModel) => p.id !== id))
	}

	// const navigateNext = () => {
	// 	updateNota?.({ payments })
	// 	navigate('/selecionarProdutor')
	// }

	useEffect(() => {
		if (payments.length > 0) {
			updateNota?.({ payments })
			navigate('/selecionarProdutor')
		}
	}, [payments])

	const navigateBack = () => {
		navigate('/adicionarItems')
	}

	const getIcon = (method: string) => {
		if (method === 'Pix') return <FontAwesomeIcon style='brands' iconCode='pix' />
		if (method === 'Dinheiro') return <FontAwesomeIcon style='solid' iconCode='money-bill-1-wave' />
		if (method === 'Crédito') return <FontAwesomeIcon style='solid' iconCode='credit-card' />
		if (method === 'Débito') return <FontAwesomeIcon style='solid' iconCode='credit-card' />
		if (method === 'Cheque') return <FontAwesomeIcon style='solid' iconCode='money-check-dollar' />
		return <FontAwesomeIcon style='solid' iconCode='wallet' />
	}

	return (
		<div className='page-content row'>
			<PageHeader title='Forma de pagamento' />
			{items.map((p: PaymentMethodModel) => (
				<PaymentMethodButton
					payment={p}
					icon={getIcon(p.name)}
					clickHandler={selectPayment}
					isSelected={nota?.payments?.at(0)?.method?.id === p.id}
				/>
			))}
		</div>
	)
}

export { PaymentPage }
