import { moneyFormatter } from '../../utils/moneyFormatter'
import { TrashButton } from '../buttons/TrashButton'

export interface CardProps {
	id?: string
	name: string
	description?: string
	total: number
	clickHandler?: () => void
	removeHandler?: () => void
}

const Card = (props: CardProps) => {
	const { id, name, description, total, clickHandler, removeHandler } = props

	return (
		<div className='default-card'>
			<div className='top'>
				<p className='primary'>{name}</p>
				<TrashButton clickHandler={removeHandler} />
			</div>
			<div className='bottom'>
				{description && (
					<button id={id} onClick={clickHandler} className='edit-button'>
						<p className='secondary'>{description}</p>
					</button>
				)}
				<p className='title'>{moneyFormatter.format(total)}</p>
			</div>
		</div>
	)
}

export { Card }
