import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { DefaultInput } from '../../../../components/inputs/DefaultInput'
import { DefaultLabel } from '../../../../components/inputs/DefaultLabel'
import { useNotaFiscal } from '../../../components/providers/notaFiscalProvider/NotaFiscalProvider'

export interface TransporterDetails {
	plate: string
	freightType: string
	transporterVolumeQt: number
	transporterSp: string
}

interface TransporterDetailProps {
	fillDetails: (details: TransporterDetails) => void
}

const TransporterDetail = (props: TransporterDetailProps) => {
	const { fillDetails } = props
	const input = useRef<HTMLInputElement>(null)
	const confirm = useRef<HTMLButtonElement>(null)
	const { nota } = useNotaFiscal()

	const detailsInits: TransporterDetails = {
		plate: nota?.transporterPlate || '',
		freightType: nota?.freightType?.toString() || '0',
		transporterVolumeQt: nota?.transporterVolumeQt || 0,
		transporterSp: nota?.transporterSp || '',
	}

	const [details, setDetails] = useState<TransporterDetails>({ ...detailsInits })

	useEffect(() => {
		input?.current?.focus()

		document.addEventListener(
			'keydown',
			(event) => {
				if (event.code === 'Escape') {
					setDetails({ ...detailsInits })
				}
				if (event.code === 'Enter' || event.code === 'NumpadEsnter') confirm?.current?.click()
			},
			false
		)
	}, [props])

	const changePlate = (e: React.FormEvent<HTMLInputElement>) => {
		const prev = { ...details }
		setDetails({ ...prev, plate: e.currentTarget.value })
	}

	const changeFreightType = (event: SelectChangeEvent) => {
		const prev = { ...details }
		setDetails({ ...prev, freightType: event.target.value as string })
	}

	const changeVolumeQt = (e: React.FormEvent<HTMLInputElement>) => {
		const prev = { ...details }
		setDetails({ ...prev, transporterVolumeQt: parseInt(e.currentTarget.value) })
	}

	const changeVolumeSp = (e: React.FormEvent<HTMLInputElement>) => {
		const prev = { ...details }
		setDetails({ ...prev, transporterSp: e.currentTarget.value })
	}

	const click = () => {
		fillDetails(details)
		setDetails({ ...detailsInits })
	}

	const canInsertPlate =
		nota?.issuer?.person.addresses[0].cityLocation.state ===
		nota?.client?.person.addresses[0].cityLocation.state

	const canConfirm = canInsertPlate ? !!details.plate : true

	return (
		<div className='input-container'>
			{canInsertPlate && (
				<DefaultLabel title='Placa do veículo'>
					<input
						className='default-input'
						ref={input}
						type='text'
						autoFocus
						value={details.plate}
						onChange={changePlate}
						maxLength={7}
					/>
				</DefaultLabel>
			)}
			<DefaultLabel title='Tipo de frete'>
				<Select
					value={details.freightType}
					onChange={changeFreightType}
					sx={{
						width: '32vw',
						color: '#000',
						backgroundColor: '#ffffffb3',
						'&.MuiOutlinedInput-root': {
							'&.Mui-focused fieldset': {
								borderColor: '#ffffffb3',
							},
							'&:hover fieldset': {
								borderColor: '#ffffffb3',
							},
						},
					}}
					MenuProps={{
						PaperProps: {
							sx: {
								color: '#000',
								maxWidth: '32vw',
							},
						},
						MenuListProps: {
							sx: {
								'& .MuiMenuItem-root': {
									whiteSpace: 'normal',
									overflow: 'auto',
								},
							},
						},
					}}
				>
					<MenuItem value={0}>Frete do remetente (CIF)</MenuItem>
					<MenuItem value={1}>Frete do destinatário (FOB)</MenuItem>
					<MenuItem value={2}>Frete de terceiros</MenuItem>
				</Select>
			</DefaultLabel>
			<DefaultLabel title='Quantidade de volumes'>
				<DefaultInput type='number' value={details.transporterVolumeQt} onChange={changeVolumeQt} />
			</DefaultLabel>
			<DefaultLabel title='Espécie dos volumes'>
				<DefaultInput type='text' value={details.transporterSp} onChange={changeVolumeSp} />
			</DefaultLabel>

			<button
				id='kt_drawer_aside_close'
				ref={confirm}
				onClick={click}
				disabled={!canConfirm}
				className={`default-button primary ${!canConfirm && 'disabled'}`}
			>
				AVANÇAR
			</button>
		</div>
	)
}

export { TransporterDetail }
