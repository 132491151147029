import { FontAwesomeIcon } from '../icon/FontAwesomeIcon'

export interface UserIconProps {
	username: string
	clickHandler?: () => void
}

const UserIcon = ({ username, clickHandler }: UserIconProps) => {
	return (
		<button className='userIcon' onClick={clickHandler}>
			<p className='primary'>{username}</p>
			<FontAwesomeIcon iconCode='circle-user' />
		</button>
	)
}

export { UserIcon }
