import { TransactionModel } from '../models/TransactionModel'

interface CfopCardProps {
	cfop: TransactionModel
	clickHandler: (cfop: TransactionModel) => void
}

const CfopCard = (props: CfopCardProps) => {
	const { cfop, clickHandler } = props
	const { code, description } = cfop

	const click = () => {
		clickHandler(cfop)
	}

	return (
		<button className='cfop-card' onClick={click}>
			<div>
				<p className='primary'>C.F.O.P</p>
				<p className='title'>{parseInt(code).toLocaleString('pt-br')}</p>
			</div>
			<p className='secondary'>{description}</p>
		</button>
	)
}

export { CfopCard }
