import { ReactNode, useEffect, useState } from 'react'
import { DefaultButton } from '../../../../components/buttons/DefaultButton'
import { Button as MUIButton } from '@mui/material'
import {
	DefaultFooterContent,
	DefaultfooterContentProps,
} from '../../../../components/footer/DefaultFooterContent'
import { useNotaFiscal } from '../../../components/providers/notaFiscalProvider/NotaFiscalProvider'

interface TransporterPageFooterProps {
	goNext?: boolean
	goBack?: boolean
	navigateNext?: () => void
	navigateBack?: () => void
}

const TransporterPageFooter = (props: TransporterPageFooterProps) => {
	const { goNext, goBack, navigateBack, navigateNext } = props
	const { updateNota } = useNotaFiscal()
	const [content, setContent] = useState<ReactNode>()
	const [config, setConfig] = useState<DefaultfooterContentProps>({})

	const skipTransporter = () => {
		updateNota?.({
			transporter: undefined,
			transporterPlate: undefined,
			transporterSp: undefined,
			transporterVolumeQt: undefined,
			freightType: undefined,
		})
		navigateNext?.()
	}

	useEffect(
		() =>
			setContent(
				<>
					<button
						data-bs-toggle='modal'
						data-bs-target='#modal_resgistration'
						className='default-button tertiary'
					>
						+ CADASTRAR NOVO
					</button>
					<MUIButton onClick={skipTransporter} variant='contained' color='primary' size='medium'>
						PULAR
					</MUIButton>
				</>
			),
		[]
	)

	useEffect(() => {
		const noHidden = true
		setConfig({
			goBack,
			goNext,
			nextClick: navigateNext,
			backClick: navigateBack,
			content,
			noHidden,
		})
	}, [content])

	return <DefaultFooterContent {...config} />
}

export { TransporterPageFooter }
