import { useBasicModal } from '../../app/components/providers/basicModalProvider/BasicModalProvider'
import Modal from '@mui/material/Modal'

const BasicModal = () => {
	const { values } = useBasicModal()
	return (
		<Modal keepMounted open={values?.isOpen ? true : false}>
			<div className='basic-modal-container'>{values?.basicModalContent}</div>
		</Modal>
	)
}

export { BasicModal }
