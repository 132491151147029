import { useEffect, useState } from 'react'
import { NavigatorButton } from '../buttons/NavigatorButton'
import { NotaFiscalModel } from '../../app/modules/notaFiscal/models/NotaFiscalModels'
import { IssueViewProps } from '../../app/modules/notaFiscal/components/IssueView'
import { Date } from '../date/Date'

interface NavHeaderProps {
	nfe: NotaFiscalModel
	views: IssueViewProps[]
	changeView?: (view: IssueViewProps) => void
}

const NavigatorHeader = (props: NavHeaderProps) => {
	const { nfe, views, changeView } = props
	const [selectedView, setSelectedView] = useState<IssueViewProps>(views[0])

	useEffect(() => {
		setSelectedView?.(views[0])
	}, [nfe])

	useEffect(() => {
		changeView?.(selectedView)
	}, [selectedView])

	const onViewButtonClick = (view: IssueViewProps) => {
		setSelectedView(view)
	}

	return (
		<div className='navigator-header'>
			<div className='title'>
				<p className='primary'>
					Nota {nfe.series}/{nfe.invoiceNumber}
				</p>
				{nfe.openDate && <Date time={nfe.openDate} />}
			</div>
			<div className='navigator-buttons'>
				{views?.map((v: IssueViewProps) => (
					<NavigatorButton
						view={v}
						selected={v.id === selectedView.id}
						clickHandler={onViewButtonClick}
					/>
				))}
			</div>
		</div>
	)
}

export { NavigatorHeader }
