import { moneyFormatter } from '../../../../utils/moneyFormatter'
import { getStatusByNumber } from '../constants/NotaFiscalStatus'
import { NotaFiscalListModel } from '../models/NotaFiscalModels'
import { Date } from '../../../../components/date/Date'

interface NFEButtonProps {
	nfe: NotaFiscalListModel
	clickHandler: (id: number) => void
	isNfeSelected: (nfeId: number) => boolean
}

const NFEButton = (props: NFEButtonProps) => {
	const { nfe, clickHandler, isNfeSelected } = props
	const { totalAmount, client, openDate, status, id } = nfe
	const nfeStatus = getStatusByNumber(status)

	const click = () => {
		clickHandler(id)
	}

	return (
		<button
			key={nfe.id}
			className={`issue-button ${nfeStatus.toLowerCase()} ${
				isNfeSelected(nfe.id) ? 'selected' : ''
			}`}
			onClick={click}
		>
			<div className='container left'>
				{openDate && <Date time={openDate} fromNow />}
				{client && <p className='secondary issue-client-name'>{client.person.name}</p>}
			</div>
			<div className={'container right'}>
				<p className={`status ${nfeStatus.toLowerCase()}`}>{nfeStatus.toUpperCase()}</p>
				{totalAmount && <p className='primary'>{moneyFormatter.format(parseFloat(totalAmount))}</p>}
			</div>
		</button>
	)
}

export { NFEButton }
