import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { PageHeader } from '../../../components/pageHeader/PageHeader'
import { StepStack } from '../../../components/stepStack/StepStack'
import { TransactionService } from '../../../services/transaction/transactionService'
import { useAsideModal } from '../../components/providers/asideModalProvider/AsideModalProvider'
import { useAside } from '../../components/providers/asideProvider/AsideProvider'
import { useLoader } from '../../components/providers/loaderProvider/LoaderProvider'
import { useNotaFiscal } from '../../components/providers/notaFiscalProvider/NotaFiscalProvider'
import { TransactionTypeButton } from '../../modules/transaction/components/TransactionTypeButton'
import { TransactionCategoryModel } from '../../modules/transaction/models/TransactionCategoryModel'
import { TransactionModel } from '../../modules/transaction/models/TransactionModel'
import { CfopList } from '../../modules/transaction/components/CfopList'
import { useFooter } from '../../components/providers/footerProvider/FooterProvider'

const TransactionTypePage = () => {
	const { isLoading, data } = useQuery('cfops', () => TransactionService.list())
	const [transactionCategory, setTransactionCategory] = useState<TransactionCategoryModel[]>([])
	const setLoading = useLoader()
	const { updateState, toggleModal } = useAsideModal()
	const { setLeftAsideContent } = useAside()
	const { setFooterContent } = useFooter()
	const { updateNota, nota } = useNotaFiscal()
	const navigate = useNavigate()
	const [selectedTransaction, setSelectedTransaction] = useState<TransactionCategoryModel>()
	const [selectedCfop, setSelectedCfop] = useState<TransactionModel>()

	useEffect(() => {
		setFooterContent?.(null)
		setLeftAsideContent?.(<StepStack />)
	}, [])

	useEffect(() => {
		setLoading && setLoading(isLoading)
	}, [isLoading])

	useEffect(() => {
		data && setTransactionCategory(data)
	}, [data])

	const selectTransaction = (t: TransactionCategoryModel) => {
		toggleModal?.(true)
		setSelectedTransaction(t)
	}

	const selectCfop = (c: TransactionModel) => {
		toggleModal?.(false)
		setSelectedCfop(c)
	}

	useEffect(() => {
		if (selectedTransaction) {
			updateState?.({
				anchor: 'right',
				asideModalContent: (
					<CfopList
						{...{
							cfops: selectedTransaction.accountCfops.filter((c) => c.active),
							clickHandler: selectCfop,
						}}
					/>
				),
				isOpen: true,
			})
		}
	}, [selectedTransaction])

	useEffect(() => {
		if (selectedCfop) {
			fillAndGoToNextStep()
		}
	}, [selectedCfop])

	const fillAndGoToNextStep = () => {
		if (!selectedCfop) return
		updateNota?.({ cfop: selectedCfop.code })
		navigate('/adicionarItems')
	}

	const orderedTransactions = transactionCategory.sort(
		(a: TransactionCategoryModel, b: TransactionCategoryModel) => {
			if (a.id === 1) return 1
			else if (b.id === 1) return -1
			return a.id - b.id
		}
	)

	return (
		<div className='page-content row stretch'>
			<PageHeader title='Informe o tipo de transação' />
			{orderedTransactions
				.filter((cat: TransactionCategoryModel) => cat.active)
				.map((cat: TransactionCategoryModel) => (
					<TransactionTypeButton value={cat} largeButton={true} clickHandler={selectTransaction} />
				))}
		</div>
	)
}

export { TransactionTypePage }
