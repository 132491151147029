import { Card } from '../../../../components/cards/Card'
import { PaymentModel } from '../models/PaymentModel'

interface SelectedMethodCardProps {
	payment: PaymentModel
	removeHandler?: (id: number) => void
}

const SelectedPaymentCard = (props: SelectedMethodCardProps) => {
	const { payment, removeHandler } = props
	const { method, value } = payment

	const removePayment = () => {
		removeHandler?.(payment.id)
	}

	const data = {
		name: method.name,
		total: value,
		removeHandler: removePayment,
	}

	return <Card {...data} />
}

export { SelectedPaymentCard }
