import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import 'dotenv/config'
import environment from './app/config/environment'

// Apps
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'
import { AppRoutes } from './app/routing/AppRoutes'
import { AppState, Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react'
import { PropsWithChildren } from 'react'
import { BrowserRouter, useNavigate } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import { theme } from './theme'

const { PUBLIC_URL } = process.env

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: false,
			refetchOnWindowFocus: false,
		},
	},
})

const Auth0ProviderWithRedirectCallback = ({
	children,
	...props
}: PropsWithChildren<Auth0ProviderOptions>) => {
	const navigate = useNavigate()

	const onRedirectCallback = (appState?: AppState) => {
		navigate((appState && appState.returnTo) || window.location.pathname)
	}

	return (
		<Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
			{children}
		</Auth0Provider>
	)
}

ReactDOM.render(
	<BrowserRouter basename={PUBLIC_URL}>
		<ThemeProvider theme={theme}>
			<Auth0ProviderWithRedirectCallback
				domain={environment.getDomain()}
				clientId={environment.getClientId()}
				redirectUri={window.location.origin}
			>
				<QueryClientProvider client={queryClient}>
					<AppRoutes />
				</QueryClientProvider>
			</Auth0ProviderWithRedirectCallback>
		</ThemeProvider>
	</BrowserRouter>,
	document.getElementById('root')
)
