import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { Homepage } from '../pages/HomePage/HomePage'
import { NotaFiscalDetailPage } from '../pages/NotaFiscalDetailPage/NotaFiscalDetailPage'
import { StepI, useNotaFiscal } from '../components/providers/notaFiscalProvider/NotaFiscalProvider'
import { PlansPage } from '../pages/PlansPage/PlansPage'
import { PlanProtectedRoute } from './PlanProtectedRoute'
import { VerififyEmailPage } from '../pages/VerifyEmailPage/VerifyEmailPage'
import { useAuth0 } from '@auth0/auth0-react'

const PrivateRoutes = () => {
	const { user } = useAuth0()
	const { steps } = useNotaFiscal()

	// const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))

	return (
		<>
			{user?.email_verified ? (
				<Routes>
					<Route element={<MasterLayout />}>
						<Route index element={<Navigate to='/homepage' />} />
						<Route path='/homepage' element={<Homepage />} />
						{steps?.map((s: StepI) => {
							const element = <PlanProtectedRoute>{s.element as JSX.Element}</PlanProtectedRoute>
							return <Route path={s.location} element={element} />
						})}
						<Route path='/detalhesDaNota' element={<NotaFiscalDetailPage />} />
						<Route path='/planos' element={<PlansPage />} />
						{/* Pages */}
						{/* <Route path='dashboard' element={<DashboardWrapper />} /> */}

						{/* Lazy Modules */}

						{/* <Route
					path='crafted/widgets'
					element={
						<SuspensedView>
							<WizardsPage />
						</SuspensedView>
					}
				/> */}
					</Route>

					{/* Page Not Found */}
					<Route path='*' element={<Navigate to='/error/404' />} />
					{/* </Route> */}
				</Routes>
			) : (
				<Routes>
					<Route element={<MasterLayout />}>
						<Route index element={<Navigate to='/homepage' />} />
						<Route path='/homepage' element={<VerififyEmailPage />} />
					</Route>
				</Routes>
			)}
		</>
	)
}

// const SuspensedView: FC = ({children}) => {
// 	const baseColor = getCSSVariableValue('--bs-primary')
// 	TopBarProgress.config({
// 		barColors: {
// 			'0': baseColor,
// 		},
// 		barThickness: 1,
// 		shadowBlur: 5,
// 	})
// 	return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
// }

export { PrivateRoutes }
