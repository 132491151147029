import { DefaultBasicModal, DefaultBasicModalProps } from '../defaultBasicModal/DefaultBasicModal'

const CancelIssueModal = (props: { cancelHandler: () => void }) => {
	const { cancelHandler } = props

	const config: DefaultBasicModalProps = {
		title: 'Confirmar',
		description: 'Tem certeza de que deseja interromper a emissão desta nota fiscal?',
		primaryButtonText: 'Confimar',
		primaryClick: cancelHandler,
		secondaryButtonText: 'Cancelar',
	}

	return <DefaultBasicModal {...config} />
}

export { CancelIssueModal }
