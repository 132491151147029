import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'
import { Navigate } from 'react-router-dom'

export interface PlanProtectedRouteProps {
	children: JSX.Element
}

export const PlanProtectedRoute = ({ children }: PlanProtectedRouteProps) => {
	const { user } = useAuth0()

	const canAccess =
		user?.app_metadata.plan?.type !== 'FREE' && !user?.app_metadata?.deny_access_on_hold

	if (!canAccess) return <Navigate to='/homepage' />
	return children
}
