import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '../icon/FontAwesomeIcon'

interface SearchProps {
	onChangeHandler?: (input: string) => void
}

const Search = (props: SearchProps) => {
	const [input, setInput] = useState<string>('')
	const { onChangeHandler } = props

	useEffect(() => {
		onChangeHandler?.(input)
	}, [input])

	const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
		const input = e.currentTarget.value
		setInput(input)
	}

	return (
		<div className='default-search'>
			<FontAwesomeIcon iconCode='magnifying-glass' style='solid' />
			<input
				className='search-input'
				id='search-input'
				name='search-input'
				type='text'
				onChange={handleChange}
				value={input}
				placeholder='Pesquisar'
			/>
		</div>
	)
}

export { Search }
