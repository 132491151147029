import { PageHeader } from '../../../components/pageHeader/PageHeader'
import { Button as MUIButton } from '@mui/material'
import { useEffect, useState } from 'react'
import wordpress, { WordpressProduct } from '../../../services/api/wordpress'
import { useNavigate } from 'react-router-dom'

const PlansPage = () => {
	const navigate = useNavigate()
	const [products, setProducts] = useState<WordpressProduct[]>([])

	const fetchProducts = async () => {
		const recurrent = await wordpress.getAllRecurrentProducts()
		const prepaid = await wordpress.getAllPrepaidProducts()
		setProducts([...recurrent.data, ...prepaid.data])
	}

	useEffect(() => {
		fetchProducts()
	}, [])

	return (
		<div className='page-content'>
			<PageHeader title={'Planos:'} />
			<div className='row'>
				{products
					.filter((p) => p.purchasable)
					.map((p) => (
						<div className='plans-list-item'>
							<div className='plan-name'>{p.sku}</div>
							<div className='plan-price'>R${p.price.toString().replace('.', ',')}</div>
							<MUIButton
								onClick={() => window.open(p.permalink)}
								variant='contained'
								color='primary'
								size='small'
							>
								Assinar
							</MUIButton>
						</div>
					))}
			</div>
			<MUIButton
				style={{ marginTop: '8vw' }}
				onClick={() => navigate('/homepage')}
				variant='contained'
				color='primary'
				size='medium'
			>
				Voltar ao início
			</MUIButton>
		</div>
	)
}

export { PlansPage }
