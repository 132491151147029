import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { HeaderWrapper } from './components/header/HeaderWrapper'
import { Toolbar } from './components/toolbar/Toolbar'
import { ScrollTop } from './components/ScrollTop'
import { Content } from './components/Content'
import { PageDataProvider, useLayout } from './core'
import { useLocation } from 'react-router-dom'
import { MenuComponent } from '../assets/ts/components'
import { Aside } from '../../components/aside/Aside'
import { AsideModal } from '../../components/asideModal/AsideModal'
import { BasicModal } from '../../components/basicModal/BasicModal'
import { ClientModal } from '../../app/modules/client/components/ClientModal'
import { TransporterModal } from '../../app/modules/transporter/components/TransporterModal'
import { Footer } from '../../components/footer/Footer'
import { RegistrationModal } from '../../components/registrationModal/RegistrationModal'
import { Version } from '../../components/version/Version'

const MasterLayout = () => {
	const location = useLocation()
	const { config } = useLayout()

	useEffect(() => {
		setTimeout(() => {
			MenuComponent.reinitialization()
		}, 500)
	}, [])

	useEffect(() => {
		setTimeout(() => {
			MenuComponent.reinitialization()
		}, 500)
	}, [location.key])

	return (
		<PageDataProvider>
			<div className='page d-flex flex-row flex-column-fluid'>
				<BasicModal />
				<RegistrationModal />
				{config.aside.display && <Aside />}
				<div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
					{config.header.display && <HeaderWrapper />}
					<div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
						{config.toolbar.display && <Toolbar />}
						<div className='post d-flex flex-column-fluid' id='kt_post'>
							{/* <div className='pageContent'> */}
							<Content>
								<Outlet />
							</Content>
							{/* </div> */}
						</div>
					</div>
				</div>
				<Footer />
				{config.rightAside.display && <Aside right />}
			</div>
			
			<AsideModal />
			{/* <ClientModal />
			<TransporterModal /> */}
			<ScrollTop />
			<Version />
		</PageDataProvider>
	)
}

export { MasterLayout }
