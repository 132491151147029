import axios from 'axios'

export interface WordpressProduct {
	purchasable: boolean
	price: number
	sku: string
	permalink: string
}

const apiWordpress = axios.create({
	baseURL: 'https://acnfagro.com.br/wp-json/wc/v3',
	timeout: 10000,
})

const getAllRecurrentProducts = () =>
	apiWordpress.get('/products/8206/variations', {
		params: {
			consumer_key: 'ck_ab69ad0700bb6c7b7bac439d3e9c6de2b3b6c014',
			consumer_secret: 'cs_b8c762d60a83b7c90e067827b92863ddfb1c7527',
		},
	})

const getAllPrepaidProducts = () =>
	apiWordpress.get('/products/9571/variations', {
		params: {
			consumer_key: 'ck_ab69ad0700bb6c7b7bac439d3e9c6de2b3b6c014',
			consumer_secret: 'cs_b8c762d60a83b7c90e067827b92863ddfb1c7527',
		},
	})

export default {
	getAllRecurrentProducts,
	getAllPrepaidProducts,
}
