import Drawer from '@mui/material/Drawer'
import { useAsideModal } from '../../app/components/providers/asideModalProvider/AsideModalProvider'
import { IconButton } from '../buttons/IconButton'

const AsideModal = () => {
	const { values, toggleModal } = useAsideModal()

	const closeModal = () => {
		toggleModal?.(false)
	}

	return (
		<Drawer classes={{ paper: 'aside-modal-acnf' }} anchor={values?.anchor} open={values?.isOpen}>
			<div className='aside-modal-header'>
				<IconButton style='solid' iconCode='x' clickHandler={closeModal} />
			</div>
			<div className='aside-modal-container'>{values?.asideModalContent}</div>
		</Drawer>
	)
}

export { AsideModal }
