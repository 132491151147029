import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { IssuerModel } from '../../modules/producer/models/IssuerModel'
import { useLoader } from '../../components/providers/loaderProvider/LoaderProvider'
import { PersonButton } from '../../../components/buttons/PersonButton'
import { useAside } from '../../components/providers/asideProvider/AsideProvider'
import { StepStack } from '../../../components/stepStack/StepStack'
import { PageHeader } from '../../../components/pageHeader/PageHeader'
import { useNotaFiscal } from '../../components/providers/notaFiscalProvider/NotaFiscalProvider'
import { ClientModel } from '../../modules/client/models/ClientModel'
import { TransporterModel } from '../../modules/transporter/models/TransporterModel'
import { TransporterService } from '../../../services/transporter/transporterService'
import { useFooter } from '../../components/providers/footerProvider/FooterProvider'
import { TransporterPageFooter } from '../../modules/transporter/components/TransporterPageFooter'
import { useAsideModal } from '../../components/providers/asideModalProvider/AsideModalProvider'
import {
	TransporterDetail,
	TransporterDetails,
} from '../../modules/transporter/components/TransporterDetail'
import { Masks } from '../../../services/utils/masks/masks'
import { useRegistrationModal } from '../../components/providers/registrationModalProvider/RegistrationModalProvider'
import { TransporterModal } from '../../modules/transporter/components/TransporterModal'
import { notifyError, notifySuccess } from '../../../components/toasts/toasts'
import { EntityFilterParams } from '../../../services/api/params'
import { Search } from '../../../components/search/Search'
import { SmallLoader } from '../../../components/smallLoader/SmallLoader'
import { TextLoader } from '../../../components/smallLoader/TextLoader'

const TransporterPage = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const { setFooterContent } = useFooter()
	const { setLeftAsideContent } = useAside()
	const { setModalContent } = useRegistrationModal()
	const { updateState, toggleModal } = useAsideModal()
	const { updateNota, nota } = useNotaFiscal()
	const { isLoading, data } = useQuery('transporter', () => TransporterService.list())
	const [filters, setFilters] = useState<EntityFilterParams>({ person: { name: '' } })
	const [transporterList, setTransporterList] = useState<TransporterModel[]>([])
	const [selectedTransporter, setSelectedTransporter] = useState<
		IssuerModel | ClientModel | TransporterModel
	>()
	const setLoading = useLoader()

	const goBack = () => navigate('/selecionarDestinatario', { state: { from: location.pathname } })

	const goNext = () => navigate('/revisao', { state: { from: location.pathname } })

	const updateList = async () => {
		setTransporterList([])
		//setLoading?.(true)
		await TransporterService.list().then((res) => {
			setTransporterList(res)
		})
		//setLoading?.(false)
	}

	useEffect(() => {
		setLeftAsideContent?.(<StepStack />)
		setFooterContent?.(
			<TransporterPageFooter goBack={true} navigateBack={goBack} navigateNext={goNext} />
		)
		setModalContent?.(<TransporterModal updateList={updateList} />)
	}, [])

	//useEffect(() => setLoading?.(isLoading), [isLoading])

	useEffect(() => {
		if (selectedTransporter) {
			updateState?.({
				anchor: 'right',
				asideModalContent: <TransporterDetail fillDetails={fillTransporterDetails} />,
				isOpen: true,
			})
		}
	}, [selectedTransporter])

	const selectTransporter = (entity: IssuerModel | ClientModel | TransporterModel) => {
		setSelectedTransporter(entity)
		toggleModal?.(true)
	}

	const fillTransporterDetails = (details: TransporterDetails) => {
		toggleModal?.(false)
		updateNota?.({
			transporter: selectedTransporter as TransporterModel,
			transporterPlate: Masks.formatPlate(details.plate),
			transporterSp: details.transporterSp,
			transporterVolumeQt: details.transporterVolumeQt,
			freightType: parseInt(details.freightType),
		})
		goNext()
	}

	const deleteTransporter = async (id: number) => {
		setLoading?.(true)
		await TransporterService.del(id)
			.then(() => {
				updateList()
			})
			.catch(() => {
				notifyError('Não foi possível deletar esta transportadora.')
			})
		setLoading?.(false)
	}

	useEffect(() => data && setTransporterList(data), [data])

	const updateName = (name: string) => {
		setFilters({ person: { name: name } })
	}

	const filterTest = (name: string, filter: string) =>
		name.toLowerCase().includes(filter.toLowerCase() || '')

	const filteredList = transporterList.filter((t: TransporterModel) =>
		filterTest(t.person.name || '', filters.person.name || '')
	)

	return (
		<div className='person-button-container'>
			<PageHeader title='Selecione a transportadora' />
			<Search onChangeHandler={updateName} />
			{transporterList.length > 0 ? (
				filteredList.length > 0 ? (
					filteredList.map((transporter: TransporterModel) => (
						<PersonButton
							entity={transporter}
							onClick={selectTransporter}
							handleDelete={deleteTransporter}
							isSelected={nota?.transporter?.id === transporter.id}
						/>
					))
				) : (
					<p className='primary'>Nenhum resultado.</p>
				)
			) : (
				<TextLoader text='Carregando transportadoras' position='bottom' />
			)}
		</div>
	)
}

export { TransporterPage }
