import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PageHeader } from '../../../components/pageHeader/PageHeader'
import { StepStack } from '../../../components/stepStack/StepStack'
import { useAsideModal } from '../../components/providers/asideModalProvider/AsideModalProvider'
import { useAside } from '../../components/providers/asideProvider/AsideProvider'
import { useFooter } from '../../components/providers/footerProvider/FooterProvider'
import { useNotaFiscal } from '../../components/providers/notaFiscalProvider/NotaFiscalProvider'
import { NFEDetail } from '../../modules/notaFiscal/components/NFEDetail'
import { ReviewFooter } from '../../modules/review/components/ReviewFooter'
import { ReviewInput } from '../../modules/review/components/ReviewInput'

const ReviewPage = () => {
	const navigate = useNavigate()
	const { nota, updateNota } = useNotaFiscal()
	const { setLeftAsideContent } = useAside()
	const { setFooterContent } = useFooter()
	const { updateState, toggleModal } = useAsideModal()
	const [acronym, setAcronym] = useState<string | undefined>('')

	useEffect(() => {
		setLeftAsideContent?.(<StepStack />)
		setFooterContent?.(<ReviewFooter {...{ backClick: navigateBack }} />)
	}, [])

	useEffect(() => {
		updateNota?.({ infCpl: acronym })
	}, [acronym])

	const setAdditionalInfo = () => {
		updateState?.({
			anchor: 'right',
			asideModalContent: <ReviewInput {...{ clickHandler: confirmInput }} />,
			isOpen: true,
		})
	}

	const confirmInput = (acronym: string | undefined) => {
		toggleModal?.(false)
		setAcronym(acronym)
	}

	const navigateBack = () => {
		navigate('/selecionarTransportadora')
	}

	return (
		<div className='page-content'>
			<PageHeader title='Confira se as informações da nota estão corretas' />
			<NFEDetail {...{ nfe: { ...nota }, editable: true, renderFooter: false }} />
			<button onClick={setAdditionalInfo} className={`edit-button ${acronym && 'hidden'}`}>
				<p className='title'>Adicionar informação complementar</p>
			</button>
		</div>
	)
}

export { ReviewPage }
