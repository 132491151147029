interface BaseConfig {
	environment: string
	baseApiUrl: string
	fullApiUrl: string
}

const LocalConfig: BaseConfig = {
	environment: 'local',
	baseApiUrl: 'http://localhost:5000',
	fullApiUrl:
		`http://localhost:5000/nfp-webapp/${process.env.REACT_APP_API_VERSION}` ||
		'http://localhost:5000/nfp-webapp/v1',
}

const DevelopmentConfig: BaseConfig = {
	environment: 'development',
	baseApiUrl: process.env.REACT_APP_AUTH0_AUDIENCE || 'https://api-develop.acnfagro.com.br',
	fullApiUrl:
		`${process.env.REACT_APP_AUTH0_AUDIENCE}/nfp-webapp/${process.env.REACT_APP_API_VERSION}` ||
		'https://api-develop.acnfagro.com.br/nfp-webapp/v1',
}

const StagingConfig: BaseConfig = {
	environment: 'staging',
	baseApiUrl: process.env.REACT_APP_AUTH0_AUDIENCE || 'https://api-develop.acnfagro.com.br',
	fullApiUrl:
		`${process.env.REACT_APP_AUTH0_AUDIENCE}/nfp-webapp/${process.env.REACT_APP_API_VERSION}` ||
		'https://api-develop.acnfagro.com.br/nfp-webapp/v1',
}

const ProductionConfig: BaseConfig = {
	environment: 'production',
	baseApiUrl: process.env.REACT_APP_AUTH0_AUDIENCE || 'https://api.acnfagro.com.br',
	fullApiUrl:
		`${process.env.REACT_APP_AUTH0_AUDIENCE}/nfp-webapp/${process.env.REACT_APP_API_VERSION}` ||
		'https://api.acnfagro.com.br/nfp-webapp/v1',
}

const configs = [LocalConfig, DevelopmentConfig, StagingConfig, ProductionConfig]

const getEnvironment = () => {
	return process.env.REACT_APP_ENVIRONMENT || DevelopmentConfig.environment
}

const getConfig = () =>
	configs.find((config: BaseConfig) => config.environment === getEnvironment())

const getBaseApiUrl = () => getConfig()?.baseApiUrl

const getFullApiUrl = () => getConfig()?.fullApiUrl

const getTestToken = () => process.env.REACT_APP_TEST_TOKEN

const getClientId = () => {
	return process.env.REACT_APP_AUTH0_CLIENT_ID || ''
}

const getAudience = () => {
	return process.env.REACT_APP_AUTH0_AUDIENCE || getBaseApiUrl()
}

const getDomain = () => {
	return process.env.REACT_APP_AUTH0_DOMAIN || ''
}

const getLogoutCallback = () => {
	return process.env.REACT_APP_AUTH0_LOGOUT_CALLBACK || ''
}

export default {
	getEnvironment,
	getBaseApiUrl,
	getFullApiUrl,
	getTestToken,
	getAudience,
	getDomain,
	getClientId,
	getLogoutCallback,
}
