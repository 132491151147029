import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { CorrectionService } from '../../services/correction/correctionService'
import { useLoader } from '../../app/components/providers/loaderProvider/LoaderProvider'
import { pdfjs } from 'react-pdf'
import { notifyError } from '../toasts/toasts'
import { TextLoader } from '../smallLoader/TextLoader'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface CCProps {
	notaId: number
}

const CCPdf = (props: CCProps) => {
	const { notaId } = props
	const [pdf, setPdf] = useState<any>()
	const setLoading = useLoader()

	useEffect(() => {
		setLoading?.(true)
	}, [])

	const { isLoading, error, data } = useQuery('CCUrl', () => CorrectionService.getPdf(notaId), {
		retry: false,
		cacheTime: 0,
	})

	useEffect(() => {
		data && setPdf(data)
		stopLoading()
	}, [data])

	useEffect(() => {
		if (error) {
			stopLoading()
			notifyError('Erro ao carregar carta de correção.')
		}
	}, [error])

	const stopLoading = () => setLoading?.(false)

	const pdfLoader = <TextLoader text='Carregando Carta de correção' position='bottom' />

	return pdf ? (
		<embed type='application/pdf' src={pdf} id='pdfDocument' width='100%' height='100%' />
	) : (
		pdfLoader
	)
}

export { CCPdf }
