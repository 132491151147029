const SmallLoader = () => {
	return (
		<div id='aside-loader-container' className={`loader-container aside-loader-container-small`}>
			<div className='lds-ring-small'>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
	)
}

export { SmallLoader }
