/* eslint-disable @typescript-eslint/no-explicit-any */
const toCamel = (s: any) => {
	return s.replace(/([-_][a-z])/gi, ($1: any) => {
		return $1.toUpperCase().replace('-', '').replace('_', '')
	})
}

const isArray = function (a: any) {
	return Array.isArray(a)
}

const isObject = function (o: any) {
	return o === Object(o) && !isArray(o) && typeof o !== 'function'
}

const keysToCamel = function (o: any) {
	if (isObject(o)) {
		const n: { [key: string]: any } = {}

		Object.keys(o).forEach((k) => {
			n[toCamel(k)] = keysToCamel(o[k])
		})

		return n
	} else if (isArray(o)) {
		return o.map((i: any) => {
			return keysToCamel(i)
		})
	}

	return o
}

const camelToSnakeCase = (str: string) =>
	str !== 'CFOP' ? str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`) : str

const keysToSnake = function (o: any) {
	if (isObject(o)) {
		const n: { [key: string]: any } = {}

		Object.keys(o).forEach((k) => {
			n[camelToSnakeCase(k)] = keysToSnake(o[k])
		})

		return n
	} else if (isArray(o)) {
		return o.map((i: any) => {
			return keysToSnake(i)
		})
	}

	return o
}

export { keysToCamel, keysToSnake }
