import { useState } from 'react'
import { useFormik } from 'formik'
import { NotaFiscalFilterParams } from '../../../../services/notaFiscal/notaFiscalService'
import { YupSchemas } from '../../../../services/utils/yupSchemas/YupSchemas'
import { IconButton } from '../../../../components/buttons/IconButton'
import { useBasicModal } from '../../../components/providers/basicModalProvider/BasicModalProvider'
import { Button as MUIButton } from '@mui/material'

const inits: NotaFiscalFilterParams = {
	invoice: {
		series: undefined,
		number: undefined,
	},
}

const NfeFilterModal = (props: { filterHandler: (values: NotaFiscalFilterParams) => void }) => {
	const { filterHandler } = props
	const { toggleModal } = useBasicModal()
	const [schema] = useState(YupSchemas.nfeFilters)
	const [initValues] = useState<NotaFiscalFilterParams>({ ...inits })

	const closeModal = () => {
		toggleModal?.(false)
	}

	const handleFilterSubmit = (values: NotaFiscalFilterParams) => {
		const params = {
			...values,
			invoice: {
				...(values.invoice.series && { series: values.invoice.series }),
				...(values.invoice.number && { number: values.invoice.number }),
			},
		}
		filterHandler(params as NotaFiscalFilterParams)
		closeModal()
	}

	const formik = useFormik({
		initialValues: initValues,
		validationSchema: schema,
		onSubmit: handleFilterSubmit,
	})

	const handleClean = () => {
		closeModal()
		formik.setFieldValue('invoice', { series: '', number: '' })
		filterHandler({ ...inits })
	}

	return (
		<>
			<div className='default-modal-content'>
				<div className='dafault-modal-header'>
					<p className='title'>Filtre a pesquisa</p>
					<IconButton iconCode='x' clickHandler={closeModal} />
				</div>
				<form onSubmit={formik.handleSubmit}>
					<div className='modal-input-container'>
						<label htmlFor='series' className='default-label'>
							Série
						</label>
						<input
							className={`form-control`}
							id='series'
							name='invoice.series'
							type='number'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.invoice.series}
						/>
						<label htmlFor='number' className='default-label'>
							Número
						</label>
						<input
							className={`form-control`}
							id='number'
							name='invoice.number'
							type='number'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.invoice.number}
						/>
					</div>

					<div className='default-modal-footer'>
						<MUIButton onClick={handleClean} variant='contained' color='secondary' size='medium'>
							Limpar
						</MUIButton>
						<MUIButton type='submit' variant='contained' color='primary' size='medium'>
							Filtrar
						</MUIButton>
					</div>
				</form>
			</div>
		</>
	)
}

export { NfeFilterModal }
